import { observer } from "mobx-react-lite";
import { FC, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import SelectField from "ui-new/select";
import cls from "./styles.module.scss";
import { IWriteoff, IWriteoffPosition } from "../types";
import { addSpaces } from "assets/utils";
import dayjs from "dayjs";
import { useStore } from "store";
import { toJS } from "mobx";

interface DocTableProps {
  index: number;
  writeoff: IWriteoff;
}

const DocTableComponent: FC<DocTableProps> = ({ index }) => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({ control: control, name: `writeoffs.${index}.positions` });
  const [tableSum, setTableSum] = useState(0);
  const rows = useMemo(() => {
    let sum = 0;
    const rows = fields.map((field, i) => {
      sum += (field as any).sum ?? 0;
      if (i === 0) return <MainRow key={field.id} index={index} position={field as any} />;
      return <SubRow key={field.id} index={i} position={field as any} />;
    });
    setTableSum(sum);
    return rows;
  }, [fields, index]);

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={44} />
        <col width={134} />
        <col width={134} />
        <col width={374} />
        <col width={82} />
        <col width={102} />
        <col width={266} />
      </colgroup>
      <thead className={cls["thead"]}>
        <tr className={cls["tr"]}>
          <th className={`${cls["th"]}`}>№</th>
          <th className={`${cls["th"]}`}>Номер документа</th>
          <th className={`${cls["th"]}`}>Дата документа</th>
          <th className={`${cls["th"]}`}>Товар</th>
          <th className={`${cls["th"]}`}>Кол-во</th>
          <th className={`${cls["th"]}`}>Сумма</th>
          <th className={`${cls["th"]}`}>Выбрать сотрудника</th>
        </tr>
      </thead>
      <tbody className={cls["tbody"]}>{rows}</tbody>
      <tfoot className={cls["tfoot"]}>
        <tr className={cls["tr"]}>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}>Итого</td>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}>{addSpaces(tableSum.toFixed(2))}</td>
          <td className={cls["td"]}></td>
        </tr>
      </tfoot>
    </table>
  );
};

interface MainRowProps {
  index: number;
  position: IWriteoffPosition;
}

const MainRowComponent: FC<MainRowProps> = ({ index, position }) => {
  const {
    RootStore: {
      SalaryFundStore: { employeesAndHelpers },
    },
  } = useStore();
  const { control, getValues } = useFormContext();
  const number = getValues(`writeoffs.${index}.number`);
  const date = getValues(`writeoffs.${index}.date`);

  const employeesAndHelpersData = useMemo(
    () =>
      toJS(employeesAndHelpers).map(({ id, name, surname }) => ({
        value: id,
        label: `${surname} ${name}`,
      })),
    [employeesAndHelpers]
  );

  return (
    <tr className={cls["tr"]}>
      <td className={`${cls["td"]} ${cls["num"]}`}>{index + 1}</td>
      <td className={cls["td"]}>{number}</td>
      <td className={cls["td"]}>{dayjs(date).format("DD.MM.YYYY")}</td>
      <td className={cls["td"]}>{position.name}</td>
      <td className={cls["td"]}>{position.quantity}</td>
      <td className={cls["td"]}>{addSpaces(position.sum.toFixed(2))}</td>
      <td className={cls["td"]}>
        <SelectField
          className={cls["employee-selector"]}
          control={control}
          name={`writeoffs.${index}.user_id`}
          options={employeesAndHelpersData}
          selectProps={{ placeholder: "Выберите сотрудника" }}
        />
      </td>
    </tr>
  );
};
const MainRow = observer(MainRowComponent);

interface SubRowProps {
  index: number;
  position: IWriteoffPosition;
}

const SubRow: FC<SubRowProps> = ({ index, position }) => {
  return (
    <tr className={cls["tr"]}>
      <td className={cls["td"]}></td>
      <td className={cls["td"]}></td>
      <td className={cls["td"]}></td>
      <td className={cls["td"]}>{position.name}</td>
      <td className={cls["td"]}>{position.quantity}</td>
      <td className={cls["td"]}>{addSpaces(position.sum.toFixed(2))}</td>
      <td className={cls["td"]}></td>
    </tr>
  );
};

export const DocTable = observer(DocTableComponent);
