import { FC } from "react";
import { DocumentStatusEnum } from "../types";
import cls from "../styles.module.scss";
import { ApproveArrowIcon, BucketIcon } from "pages/registry-document/icons";

interface DocumentStatusProps {
  status: keyof typeof DocumentStatusEnum;
}

const DocumentStatusComponent: FC<DocumentStatusProps> = ({ status }) => {
  if (status === "approved")
    return (
      <div className={`${cls["document-status"]} ${cls["approved"]}`}>
        <ApproveArrowIcon />
        <span>Документ утвержден</span>
      </div>
    );

  if (status === "deleted")
    return (
      <div className={`${cls["document-status"]} ${cls["deleted"]}`}>
        <BucketIcon width={"16px"} height={"16px"} />
        <span>Документ удален</span>
      </div>
    );
  return null;
};

export const DocumentStatus = DocumentStatusComponent;
