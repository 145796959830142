import { observer } from "mobx-react-lite";
import { FC, ReactNode, useMemo } from "react";
import cls from "./table-styles.module.scss";
import dayjs, { Dayjs } from "dayjs";
import { IBepUser } from "../types";
import { useStore } from "store";
import { addSpaces } from "assets/utils";

interface EmployeeProps {
  days: Dayjs[];
  employee: IBepUser;
  index: number;
}

const EmployeeComponent: FC<EmployeeProps> = ({
  days,
  index,
  employee: { name, job_title, items, results, approved, is_helper },
}) => {
  const {
    RootStore: {
      SalaryFundStore: { netOrGross },
    },
  } = useStore();

  const [itemsData, hoursData] = useMemo(() => {
    const itemsData: ReactNode[] = [];
    const hoursData: ReactNode[] = [];
    days.forEach(d => {
      const item = items.find(i => dayjs(i.date).isSame(d, "day"));
      if (!item) {
        itemsData.push(
          <>
            <td className={cls["td"]}>0</td>
            <td className={cls["td"]}>0</td>
          </>
        );
        hoursData.push(
          <>
            <td className={cls["td"]}></td>
            <td className={cls["td"]}></td>
          </>
        );
        return;
      }
      itemsData.push(
        <>
          <td className={cls["td"]}>{addSpaces(item[`amount_plan_${netOrGross}`].toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces(item[`amount_fact_${netOrGross}`].toFixed(2))}</td>
        </>
      );
      hoursData.push(
        <>
          <td className={cls["td"]}>{!item.hours_plan ? null : item.hours_plan}</td>
          <td className={cls["td"]}>{!item.hours_fact ? null : item.hours_fact}</td>
        </>
      );
    });
    return [itemsData, hoursData];
  }, [days, items, netOrGross]);

  return (
    <>
      <tr className={`${cls["tr"]} ${cls["employee"]}`}>
        <td className={`${cls["td"]} ${cls["sticky"]}`}>{index}</td>
        <td className={`${cls["td"]} ${cls["sticky"]}`} style={{ left: "44px" }} title={name}>
          <span className={cls["name"]}>{name}</span>
        </td>
        <td className={`${cls["td"]} ${cls["sticky"]}`} style={{ left: "calc(185px + 44px)" }}>
          Премия, ₽
        </td>
        {itemsData}
        <td className={cls["td"]}>{addSpaces(results[`amount_plan_${netOrGross}`].toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces(results[`amount_fact_${netOrGross}`].toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces(approved[`approved_${netOrGross}`].toFixed(2))}</td>
      </tr>
      <tr>
        <td className={`${cls["td"]} ${cls["sticky"]}`}></td>
        <td className={`${cls["td"]} ${cls["sticky"]}`} style={{ left: "44px" }}>
          {is_helper ? "Хэлпер" : job_title}
        </td>
        <td className={`${cls["td"]} ${cls["sticky"]}`} style={{ left: "calc(185px + 44px)" }}>
          Часов за день
        </td>
        {hoursData}
        <td className={cls["td"]}></td>
        <td className={cls["td"]}></td>
        <td className={cls["td"]}></td>
      </tr>
    </>
  );
};

export const Employee = observer(EmployeeComponent);
