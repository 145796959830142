import { ValidationMessages, ValidationTypes } from "assets/utils/validationMessages";
import dayjs from "dayjs";
import * as yup from "yup";

export const actCreateSchema = ({
  period_end,
  period_start,
}: {
  period_start: string;
  period_end: string;
}) =>
  yup.object({
    number: yup
      .string()
      .max(12, ({ max }) => `${ValidationMessages.get(ValidationTypes.max)} ${max} символов`)
      .test("no-only-spaces", ValidationMessages.get(ValidationTypes.required) as string, value =>
        value ? value.trim().length > 0 : false
      )
      .required(ValidationMessages.get(ValidationTypes.required)),
    date: yup
      .date()
      .typeError(
        `Выберите дату от ${dayjs(period_start).format("DD.MM")} до ${dayjs(period_end).format(
          "DD.MM"
        )}`
      )
      .test("date-range", `Выберите дату от ${period_start} до ${period_end}`, function (value) {
        if (!value) return false; // Если нет даты, тест проваливается
        const date = new Date(value);
        const isAfterMin = period_start ? date >= new Date(period_start) : true;
        const isBeforeMax = period_end ? date <= new Date(period_end) : true;
        return isAfterMin && isBeforeMax;
      })
      .required(ValidationMessages.get(ValidationTypes.required)),
    contragent_id: yup
      .number()
      .typeError(ValidationMessages.get(ValidationTypes.required) as string)
      .required(ValidationMessages.get(ValidationTypes.required)),
    pnl_article_id: yup
      .number()
      .typeError(ValidationMessages.get(ValidationTypes.required) as string)
      .required(ValidationMessages.get(ValidationTypes.required)),
    sum: yup
      .number()
      .typeError(ValidationMessages.get(ValidationTypes.required) as string)
      .min(0.01, ({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min}`)
      .required(ValidationMessages.get(ValidationTypes.required))
      .nullable()
      .test("sum-or-positions", "Поле обязательное", function (sum) {
        const { positions } = this.parent;
        if ((!positions || positions.length === 0) && !sum) {
          return false;
        }
        return true;
      }),
    positions: yup
      .array()
      .of(
        yup.object({
          pnl_article_id: yup.number().required(),
          sum: yup
            .number()
            .min(0.01, ({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min}`)
            .required(),
          act_service_id: yup.number().required(),
        })
      )
      .test("positions-or-sum", "Заполните позиции", function (positions) {
        const { sum } = this.parent;
        if ((!positions || positions.length === 0) && !sum) {
          return false;
        }
        return true;
      })
      .nullable(),
  });
