import { Dropdown, Menu, Checkbox, Space } from "antd";
import { CloseFilterIcon, FilterArrowIcon } from "pages/money-transactions/icons";
import { useStore } from "store";
import { MenuInfo } from "rc-menu/lib/interface";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { orderBy } from "lodash";

const MoneyTransactionTypeFilterComponent = () => {
  const {
    RootStore: {
      MoneyTransactionsStore: {
        moneyTransactionTypes,
        filterMoneyTransactionTypes,
        setFilterMoneyTransactionTypes,
        toggleMoneyTransactionType,
      },
    },
  } = useStore();

  const transactionTypesData = useMemo(
    () =>
      orderBy(moneyTransactionTypes, [type => type.title], ["asc"]).map(item => (
        <Menu.Item key={item.id}>
          <Checkbox checked={filterMoneyTransactionTypes.includes(item.id)}></Checkbox>
          {item.title}
        </Menu.Item>
      )),
    [filterMoneyTransactionTypes, moneyTransactionTypes]
  );

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <Menu
          onClick={(e: MenuInfo) => {
            const type = moneyTransactionTypes[+e.key - 1];
            toggleMoneyTransactionType(type.id);
          }}
        >
          {transactionTypesData}
        </Menu>
      )}
    >
      <Space className="cursor-pointer" style={{ gap: "4px" }}>
        {
          <>
            Тип операции
            {filterMoneyTransactionTypes.length > 0 ? (
              <CloseFilterIcon onClick={() => setFilterMoneyTransactionTypes([])} />
            ) : (
              <FilterArrowIcon />
            )}
          </>
        }
      </Space>
    </Dropdown>
  );
};

export const MoneyTransactionTypeFilter = observer(MoneyTransactionTypeFilterComponent);
