import {Modal} from "ui-new/modal";
import {Button} from "ui-new/button";
import {ModalCard} from "ui-new/modal-card/modalCard";
import styles from './styles.module.scss';

export interface Props {
    onClose: () => void;
    isOpen: boolean;
}

export const NotSucceedModal = ({ onClose, isOpen }: Props) => {
    return <Modal onClose={onClose} isOpen={isOpen} className={styles.modalWrapper}>
        <ModalCard onClose={onClose} className={styles.modalContainer}>
            <h2 className={styles.modalTitle}>Обновление недоступно</h2>
            <p className={styles.modalDescription}>
                Ручное обновление данных отчета доступно в период закрытия месяца (с 1 по 5 число)
            </p>
            <div className={styles.buttons}>
                <Button theme='outlined' onClick={onClose} className={styles.button}>Назад</Button>
                <Button onClick={onClose} className={styles.button}>Понятно</Button>
            </div>
        </ModalCard>
    </Modal>
}