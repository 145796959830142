import { IUser } from "pages/salary-fund/types";
import { IContragent, IPnLArticle } from "types/directories";
import { IMoneyTransactionPnLArticle } from "types/money-transactions";

//#endregion Общие интерфейсы
export interface IServiceDocument {
  id: number;
  guid_1c: string | null;
  contragent_id: IContragent["id"];
  contragent: IContragent["title"];
  responsible_id: IUser["id"];
  responsible: string;
  spot_id: number;
  //"YYYY-MM-DD"
  date: string;
  number: string;
  pnl_article_id: IMoneyTransactionPnLArticle["id"] | null;
  pnl_article_title: IMoneyTransactionPnLArticle["title"] | null;
  positions: IServicePosition[];
  //"YYYY-MM-DD HH:mm:ss"
  created_at: string;
  updated_at: string;

  status: keyof typeof DocumentStatusEnum;
}

export interface IServicePosition {
  id: number;
  pnl_article_id: IPnLArticle["id"];
  pnl_article_title: IPnLArticle["title"];
  sum: number;
  //YYYY-MM-DD HH:mm:ss
  created_at: string;
  updated_at: string;
}
//#endregion
//#region Акт по услугам
export interface IActService extends IServiceDocument {}

export interface IServiceNewPosition {
  pnl_article_id: IServicePosition["pnl_article_id"];
  sum: IActServicePosition["sum"];
}

export interface IActServiceNewPosition extends IServiceNewPosition {
  act_service_id: IActServicePosition["act_service_id"];
}

export interface IActServiceUpdatePosition extends IActServiceNewPosition {
  id: IServicePosition["id"];
}

export interface IActServicePosition extends IServicePosition {
  act_service_id: IActService["id"];
}
//#endregion
//#region Авансовый отчет
export interface IAdvanceReportService extends IServiceDocument {
  comment: string;
  positions: IAdvanceReportServicePosition[];
}

export interface IAdvanceReportServicePosition extends IServicePosition {
  advance_report_service_id: IActService["id"];
}

export interface IAdvanceReportServiceNewPosition extends IServiceNewPosition {
  advance_report_service_id: IActServicePosition["act_service_id"];
}

export interface IAdvanceReportServiceUpdatePosition extends IAdvanceReportServiceNewPosition {
  id: IServicePosition["id"];
}

export interface IAdvanceReportServicePosition extends IServicePosition {
  advance_report_service_id: IActService["id"];
}
//#endregion
export enum DocumentStatusEnum {
  "approved",
  "pending",
  "deleted",
}
