import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import cls from "../styles.module.scss";
import { IIKODeductionHeader } from "./header";
import { IIKODeductionTables } from "./tables";
import { useStore } from "store";
import { toJS } from "mobx";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { IWriteoff } from "../types";
import { showNotificationModal } from "ui-new/alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  writeoffs: yup
    .array()
    .of(
      yup.object({
        guid_1c: yup.string().required(),
        date: yup.string().required(),
        number: yup.string().required(),
        user_id: yup
          .number()
          .required("")
          .nullable()
          .when("sum", (deps, schema) => schema.nonNullable("")),
        sum: yup.number().required(),
        positions: yup
          .array()
          .of(
            yup.object({
              id: yup.string().required(),
              name: yup.string().required(),
              quantity: yup.number().required(),
              sum: yup.number().required(),
            })
          )
          .required(),
      })
    )
    .required(),
});

export interface FormValues {
  writeoffs: IWriteoff[];
}

interface IIKODeductionProps {
  onCancel: () => void;
}

const IIKODeductionComponent: FC<IIKODeductionProps> = ({ onCancel }) => {
  const {
    RootStore: {
      SalaryFundStore: { holdAndPaidByWriteoff, fetchSetByWriteoffs },
    },
  } = useStore();

  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { writeoffs: toJS(holdAndPaidByWriteoff) ?? [] },
  });
  const { reset, handleSubmit } = methods;

  useEffect(() => {
    reset({ writeoffs: toJS(holdAndPaidByWriteoff) ?? [] });
  }, [holdAndPaidByWriteoff]);

  const onSubmit = (values: FormValues) => {
    fetchSetByWriteoffs(values.writeoffs).then(onCancel);
  };
  const onError = (errors: FieldErrors<FormValues>) => {
    showNotificationModal({
      title: "Ошибка!",
      errors: ["Необходимо выбрать сотрудника"],
      type: "failure",
    });
  };

  return (
    <FormProvider {...methods}>
      <div className={cls["fund__wrapper"]}>
        <IIKODeductionHeader onCancel={onCancel} onSave={handleSubmit(onSubmit, onError)} />
        <div className={cls["tables-container"]}>
          <IIKODeductionTables />
        </div>
      </div>
    </FormProvider>
  );
};

export const IIKODeduction = observer(IIKODeductionComponent);
