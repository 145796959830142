import {Modal} from "ui-new/modal";
import {ModalCard} from "ui-new/modal-card/modalCard";
import {Button} from "ui-new/button";
import styles from './styles.module.scss';

export interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
    minWidth?: string;
    maxWidth?: string;
    value: string | number;
}

export const FillTableModal = (props: Props) => {
    const {isOpen, onClose,onSave} = props;

    return <Modal isOpen={isOpen} onClose={onClose} className={styles.modalContainer}>
        <ModalCard onClose={onClose} className={styles.modalWrapper}>
            <div className={styles.modalTitleContainer}>
                <h3 className={styles.modalTitleContainerText}>
                    Заполнить все ячейки?
                </h3>
            </div>

            <div className={styles.modalButtons}>
                <Button onClick={onClose} theme='outlined'>Отменить</Button>
                <Button onClick={onSave}>Заполнить</Button>
            </div>
        </ModalCard>
    </Modal>
}