import type {
    IncomePlanningListResponse,
    IncomePlanningStoreBody, PlanApproveResponse
} from "../types/income-planning";
import axios from "axios";
import Cookies from "js-cookie";

export const BASE_API_URL_V3 = `${process.env.REACT_APP_API_BASE}/api/v3/`;

const incomePlanningApi = axios.create({
    baseURL: BASE_API_URL_V3,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
    },
});

export async function getRevenuePlanList(date: string = '2024-11') {
    const query = new URLSearchParams();
    query.append("date", date);

    const response = await incomePlanningApi.get<{ data: IncomePlanningListResponse }>('/revenue/plan/get', { params: query });

    return response.data.data;
}

export async function storeRevenuePlan(body: IncomePlanningStoreBody) {
    const response = await incomePlanningApi.post('/revenue/plan/store', body);
    return response.data;
}

export async function revenuePlanApprove(date: string) {
    const query = new URLSearchParams();
    query.append("date", date);

    try {
        const response = await incomePlanningApi.get<{ data: PlanApproveResponse }>('/revenue/plan/approve', { params: query });
        return response.data;
    } catch {
        return null;
    }
}