import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import { toJS } from "mobx";
import { SelectTreeField } from "ui-new/select-tree";

const processTreeData = <T extends { children: T[] | null }>(nodes: T[]): T[] => {
  return nodes.map(node => {
    const isLeaf = !node.children || node.children.length === 0; // Узел — лист, если нет дочерних элементов
    return {
      ...node,
      disabled: !isLeaf, // Свойство `selectable` управляет доступностью выбора
      children: node.children ? processTreeData(node.children) : null, // Обрабатываем дочерние элементы
    };
  });
};

interface PnLFieldProps {
  allDisabled: boolean;
}

const PnLFieldComponent: FC<PnLFieldProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: { pnlArticles },
    },
  } = useStore();
  const { control } = useFormContext<{
    pnl_article_id: number;
  }>();

  const pnlArticlesData = useMemo(
    () =>
      processTreeData(
        toJS(pnlArticles).sort((a, b) => {
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
          return 0;
        })
      ),
    [pnlArticles]
  );

  return (
    <SelectTreeField
      control={control}
      name={"pnl_article_id"}
      label="Статья расходов"
      selectProps={{
        placeholder: "Выбери статью расходов",
        disabled: allDisabled,
        showSearch: true,
        treeNodeFilterProp: "title",
        fieldNames: {
          children: "children",
          label: "title",
          value: "id",
        },
      }}
      options={pnlArticlesData}
    />
  );
};

export const PnLField = observer(PnLFieldComponent);
