import { FC } from "react";
import cls from "../styles.module.scss";
import { EInputStyleVariant, Input } from "ui-new/input";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

//Ограничение ввода 12 символов, если больше, то не вводится
const onNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.value.length > 12) {
    e.target.value = e.target.value.slice(0, 12);
  }
};

interface DateAndNumberProps {
  allDisabled: boolean;
}

const DateAndNumberComponent: FC<DateAndNumberProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: { period },
    },
  } = useStore();

  const { control } = useFormContext<{ date: Date; number: number }>();

  return (
    <div className={cls["row"]}>
      <Input
        label="Дата проведения"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="date"
        name="date"
        inputProps={{
          max: !allDisabled ? period?.period_end : "",
          min: !allDisabled ? period?.period_start : "",
          disabled: allDisabled,
        }}
      />
      <Input
        label="№ документа"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="string"
        name="number"
        placeholder="Введи номер документа"
        inputProps={{
          disabled: allDisabled,
          onInput: onNumberInput,
        }}
      />
    </div>
  );
};

export const DateAndNumber = observer(DateAndNumberComponent);
