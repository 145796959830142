import { useStore } from "store";
import { SortIcon } from "../icons";

export const TableHeader = () => {
  const {
    RootStore: {
      MoneyTransactionsStore: { sortParam, sortOrder, handleColumnTitleClick },
    },
  } = useStore();

  return (
    <div className="statements-table__header">
      <div
        className="statements-table__header-element sorted f144"
        onClick={() => handleColumnTitleClick("date_delivery")}
      >
        <p>Дата проведения</p>
        <SortIcon order={sortOrder} isVisible={sortParam === "date_delivery"} />
      </div>
      <div className="statements-table__header-element f144">
        <span className="divider" />
        <p>№ документа</p>
      </div>
      <div
        className="statements-table__header-element sorted f284"
        onClick={() => handleColumnTitleClick("contragent_title")}
      >
        <span className="divider" />
        <p>Плательщик / Получатель</p>
        <SortIcon order={sortOrder} isVisible={sortParam === "contragent_title"} />
      </div>
      <div
        className="statements-table__header-element sorted f144"
        onClick={() => handleColumnTitleClick("amount")}
      >
        <span className="divider" />
        <p>Сумма ₽</p>
        <SortIcon order={sortOrder} isVisible={sortParam === "amount"} />
      </div>
      <div
        className="statements-table__header-element sorted f120"
        onClick={() => handleColumnTitleClick("type_id")}
      >
        <span className="divider" />
        <p>Счет / Касса</p>
        <SortIcon order={sortOrder} isVisible={sortParam === "type_id"} />
      </div>
      <div
        className="statements-table__header-element sorted f290"
        onClick={() => handleColumnTitleClick("operation_type_title")}
      >
        <span className="divider" />
        <p>Вид операции</p>
        <SortIcon order={sortOrder} isVisible={sortParam === "operation_type_title"} />
      </div>
      <div
        className="statements-table__header-element sorted f154"
        onClick={() => handleColumnTitleClick("status_id")}
      >
        <span className="divider" />
        <p>Статус</p>
        <SortIcon order={sortOrder} isVisible={sortParam === "status_id"} />
      </div>
    </div>
  );
};
