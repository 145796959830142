import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo } from "react";
import cls from "./styles.module.scss";
import { EmployeeRow } from "./employee-row";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useStore } from "store";
import { FormValues, ISalary } from "pages/money-transactions/money-transaction-edit/types";
import { addSpaces } from "assets/utils";

export interface INeedleToPay extends ISalary {
  status_title: string;
}

const newUser = {
  amount: null as unknown as number,
  status_title: "Добавлено",
  user_id: null as unknown as number,
};

interface EmployeesTableProps {
  allDisabled: boolean;
}

const EmployeesTableComponent: FC<EmployeesTableProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: {
        fetchGetEmployeesAndHelpers,
        employeesAndHelpers,
        transaction,
        fetchGetNeedleToPay,
      },
    },
  } = useStore();

  useEffect(() => {
    if (transaction && transaction.items && transaction.items.length > 0) return;
    fetchGetNeedleToPay().then(response =>
      setValue(
        "items",
        response.map(({ needle, status_title, user_id }) => ({
          amount: needle,
          status_title,
          user_id,
        }))
      )
    );
  }, [transaction]);

  const { control, setValue, watch, formState } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const amount = watch("amount");
  const items = watch("items");
  const sum = items?.reduce((acc, val) => acc + Number(val.amount ?? 0), 0);

  useEffect(() => {
    if (employeesAndHelpers.length > 0) return;
    fetchGetEmployeesAndHelpers();
  }, [employeesAndHelpers.length, fetchGetEmployeesAndHelpers]);

  const employeesData = useMemo(
    () => employeesAndHelpers.map(emp => ({ label: `${emp.surname} ${emp.name}`, value: emp.id })),
    [employeesAndHelpers]
  );

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={576} />
        <col width={272} />
        <col width={275} />
        <col width={40} />
      </colgroup>
      <thead className={cls["thead"]}>
        <tr className={cls["tr"]}>
          <th className={cls["th"]}>Сотрудники</th>
          <th className={cls["th"]}>Статус утверждения</th>
          <th className={cls["th"]}>Сумма выплаты</th>
          <th className={cls["th"]}></th>
        </tr>
      </thead>
      <tbody className={cls["tbody"]}>
        {fields.map((fieldProps, i, arr) => {
          return (
            <EmployeeRow
              allDisabled={allDisabled}
              key={fieldProps.id}
              employee={fieldProps}
              employeesData={employeesData}
              index={i}
              lastIndex={arr.length - 1}
              control={control}
              remove={remove}
            />
          );
        })}
      </tbody>
      <tfoot className={cls["tfoot"]}>
        <tr className={`${cls["tr"]} ${cls["buttons-footer"]}`}>
          <td className={cls["td"]} colSpan={4}>
            <div>
              <button
                className={cls["reset-button"]}
                onClick={() => append(newUser)}
                disabled={allDisabled}
              >
                Добавить сотрудника
              </button>
            </div>
          </td>
        </tr>
        <tr className={`${cls["tr"]} ${cls["sum-footer"]}`}>
          <td className={cls["td"]} colSpan={2}>
            <span className={cls["error-message"]}>
              {Array.isArray(formState.errors.items)
                ? "Заполните таблицу или удалите лишние строки"
                : formState.errors.items?.root?.message}
            </span>
          </td>
          <td className={cls["td"]} colSpan={2}>
            <div className={cls["sum"]}>
              <p>Итого выплачено:</p>
              <span style={+amount !== +sum ? { color: "#EB5757" } : {}}>
                {sum ? addSpaces(sum.toFixed(2)) : 0.0}
              </span>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export const EmployeesTable = observer(EmployeesTableComponent);
