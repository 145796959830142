import {
  IActService,
  IActServiceNewPosition,
  IActServicePosition,
  IActServiceUpdatePosition,
  IAdvanceReportService,
  IAdvanceReportServiceNewPosition,
  IAdvanceReportServicePosition,
  IAdvanceReportServiceUpdatePosition,
} from "pages/registry-document/services/types";
import { api } from "./api-new";
import { onError } from "./schedulePlanning";
import { IBaseResponse } from "./api";
import { IPnLArticleTree } from "types/directories";

//#region Акт по услугам
export async function getActService(id: IActService["id"]) {
  try {
    const response = await api.get<IBaseResponse<IActService>>("documents/act_service/get", {
      params: { id },
    });
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export interface CreateActServiceProps {
  number: string;
  //YYYY-MM-DD
  date: string;
  contragent_id: number;
}

export async function createActService(body: CreateActServiceProps) {
  try {
    const response = await api.post<IBaseResponse<IActService>>(
      "documents/act_service/create",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export interface UpdateActServiceProps extends CreateActServiceProps {
  id: IActService["id"];
}

export async function updateActService(body: UpdateActServiceProps) {
  try {
    const response = await api.post<IBaseResponse<IActService>>(
      "documents/act_service/update",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function createActDocumentPosition(body: IActServiceNewPosition) {
  try {
    const response = await api.post<IBaseResponse<IActServicePosition>>(
      "documents/act_service/positions/create",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function updateActDocumentPosition(body: IActServiceUpdatePosition) {
  try {
    const response = await api.post<IBaseResponse<IActServicePosition>>(
      "documents/act_service/positions/update",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function deleteActDocumentPosition(id: IActService["id"]) {
  try {
    const response = await api.post<IBaseResponse<never[]>>(
      "documents/act_service/positions/delete",
      { id }
    );
    return response.data.success;
  } catch (error) {
    return onError(error);
  }
}
//#endregion

export async function getServicesPnLTree() {
  try {
    const response = await api.get<IBaseResponse<IPnLArticleTree[]>>(
      "directories/services/pnl_articles"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

//#region Авансовый отчет
export async function getAdvanceReportService(id: IAdvanceReportService["id"]) {
  try {
    const response = await api.get<IBaseResponse<IAdvanceReportService>>(
      "documents/advance_report_service/get",
      {
        params: { id },
      }
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export interface CreateAdvanceReportServiceProps {
  number: string;
  user_id: number;
  //YYYY-MM-DD
  date: string;
  contragent_id?: number | null;
  comment?: string | null;
}

export async function createAdvanceReportService(body: CreateAdvanceReportServiceProps) {
  try {
    const response = await api.post<IBaseResponse<IAdvanceReportService>>(
      "documents/advance_report_service/create",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export interface UpdateAdvanceReportServiceProps extends CreateAdvanceReportServiceProps {
  id: IAdvanceReportService["id"];
}

export async function updateAdvanceReportService(body: UpdateAdvanceReportServiceProps) {
  try {
    const response = await api.post<IBaseResponse<IAdvanceReportService>>(
      "documents/advance_report_service/update",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function createAdvanceReportDocumentPosition(body: IAdvanceReportServiceNewPosition) {
  try {
    const response = await api.post<IBaseResponse<IAdvanceReportServicePosition>>(
      "documents/advance_report_service/positions/create",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function updateAdvanceReportDocumentPosition(
  body: IAdvanceReportServiceUpdatePosition
) {
  try {
    const response = await api.post<IBaseResponse<IAdvanceReportServicePosition>>(
      "documents/advance_report_service/positions/update",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function deleteAdvanceReportDocumentPosition(id: IActServicePosition["id"]) {
  try {
    const response = await api.post<IBaseResponse<never[]>>(
      "documents/advance_report_service/positions/delete",
      { id }
    );
    return response.data.success;
  } catch (error) {
    return onError(error);
  }
}
//#endregion
