import styles from './styles.module.scss';

export interface Props {
}

export const Loader = ({}: Props) => {
    return <div className={styles.spinnerWrapper}>
        <div className={styles.ldsRing}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
}
