import styles from './styles.module.scss';
import {dayjs} from "utils/plugins/dayjs";
import {useCallback, useMemo, useRef} from "react";
import type {IncomePlanningItem} from "types/income-planning";
import classNames from "classnames";
import {capitalizeFirstLetter} from "../../../../helpers/capitalize";
import {EditableCell} from "../editable-cell/EditableCell";
import {useIntersection} from "react-use";

interface TableHeader {
    value: keyof IncomePlanningItem;
    title: string;
}

export interface Props {
    items: IncomePlanningItem[];
    isLoading: boolean;
    updateItem: (item: IncomePlanningItem, value: keyof IncomePlanningItem, newValue: number) => void;
    fillFullTable: () => void;
    setCurrentHeaderValue: (value: keyof IncomePlanningItem) => void;
}

const headers: TableHeader[] = [
    {
        value: 'date',
        title: 'Дата',
    },
    {
        value: 'receipts_count',
        title: 'Кол-во чеков в день',
    },
    {
        value: 'average_receipt',
        title: 'Средний  чек, ₽'
    },
    {
        value: 'revenue_per_day',
        title: 'Плановая выручка за день, ₽'
    },
    {
        value: 'cash_revenue',
        title: 'Наличная выручка, ₽'
    },
    {
        value: 'non_cash_revenue',
        title: 'Безналичная выручка, ₽'
    },
    {
        value: 'non_fiscal_revenue',
        title: 'Нефиксал. выручка, ₽'
    },
];

const borderedCells = ['revenue_per_day', 'cash_revenue', 'non_cash_revenue', 'non_fiscal_revenue']

export const IncomePlanningTable = (props: Props) => {
    const { items, updateItem, fillFullTable, setCurrentHeaderValue } = props;

    const theadRef = useRef(null);
    const tfootRef = useRef(null);

    const theadIntersection = useIntersection(theadRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1,
    });

    const tfootIntersection = useIntersection(tfootRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1,
    });

    const headerCellClasses = useCallback((header: TableHeader) => {
        return classNames(styles[`header-${header.value}`], styles.headerCell, styles.cell)
    }, []);

    const bodyCellClasses = useCallback((header: TableHeader) => {
        const borderedCell = borderedCells.includes(header.value);
        return classNames(styles[header.value], styles.bodyCell, styles.cell, borderedCell ? styles.mainCell : '');
    }, [])

    const renderBodyCell = useCallback((header: TableHeader, item: IncomePlanningItem, index: number) => {
        switch (header.value) {
            case "date":
                return <div className={styles.dateCell}>
                    <span className={styles.dateCellDate}>{dayjs(item.date).format('DD.MM')}</span>
                    <span className={styles.dateCellWeekday}>{capitalizeFirstLetter(dayjs(item.date).format('dddd'))}</span>
                </div>
            case "receipts_count":
            case "average_receipt":
                return <EditableCell updateItem={updateItem} item={item} headerValue={header.value} title={item[header.value]} index={index} fillFullTable={fillFullTable} setCurrentHeaderValue={setCurrentHeaderValue} />
            default:
                return renderCellNumberValue(item[header.value]);
        }
    }, [updateItem]);

    const theadRowClasses = useMemo(() => {
        if (!theadIntersection?.isIntersecting) {
            return classNames(styles.tableLayoutBoxShadow, styles.tableHeader);
        }
    }, [theadIntersection]);

    const tfootRowClasses = useMemo(() => {
        if (!tfootIntersection?.isIntersecting) {
            return classNames(styles.tableLayoutBoxShadow);
        }
    }, [tfootIntersection]);

    const calculateFooterValue = useCallback((header: TableHeader) => {
        if (header.value === 'date') return 'Итого за месяц, ₽:'

        const val = items.reduce((acc, cur) => {
            return acc + Number(cur[header.value]);
        }, 0);

        if (header.value === 'receipts_count') return renderCellNumberValue(val / items.length);
        if (header.value === 'average_receipt') return renderCellNumberValue(val / items.length);

        return renderCellNumberValue(val);
    }, [items]);

    const renderCellNumberValue = useCallback((value: number | string) => {
        if (!value) return 0;

        return new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(Number(value));
    }, []);

    return <div className={styles.incomePlanningTableContainer}>
        <table className={styles.table}>
            <thead ref={theadRef}>
                <tr className={theadRowClasses}>
                    {headers.map((header) => (
                        <th className={headerCellClasses(header)}>
                            {header.title}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
            {
                items.map((item, index) => (
                    <tr>
                        {
                            headers.map((header) => (
                                <td className={bodyCellClasses(header)}>
                                    {renderBodyCell(header, item, index)}
                                </td>
                            ))
                        }
                    </tr>
                ))
            }
            </tbody>

            <tfoot ref={tfootRef} className={styles.tableFooter}>
                <tr className={tfootRowClasses}>
                    {
                        headers.map((header) => (
                            <td className={styles.footerCell}>
                                {calculateFooterValue(header)}
                            </td>
                        ))
                    }
                </tr>
            </tfoot>
        </table>
    </div>
}