import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import { EInputStyleVariant, Input } from "ui-new/input";

interface SumFieldProps {
  allDisabled: boolean;
}

const SumFieldComponent: FC<SumFieldProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { operationTypes },
    },
  } = useStore();
  const { control, watch } = useFormContext<{ amount: number; operation_type_id: number }>();

  const operationTypeId = watch("operation_type_id");
  const currentOperationType = operationTypes.find(oT => oT.id === operationTypeId);

  return (
    <Input
      label="Сумма"
      control={control}
      variant={EInputStyleVariant.basicInput}
      placeholder="Введи сумму"
      type="number"
      name="amount"
      inputProps={{
        disabled: allDisabled || currentOperationType?.title === "Выплата заработной платы",
      }}
    />
  );
};

export const SumField = observer(SumFieldComponent);
