import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";
import { MoneyTransactionDynamicFormFields } from "types/money-transactions";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

interface CommissionFieldProps {
  allDisabled: boolean;
}

const CommissionFieldComponent: FC<CommissionFieldProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { formFields },
    },
  } = useStore();

  const { control } = useFormContext<{ [MoneyTransactionDynamicFormFields.Commission]: string }>();

  return (
    <Input
      label="Комиссия"
      control={control}
      variant={EInputStyleVariant.basicInput}
      type="number"
      name={MoneyTransactionDynamicFormFields.Commission}
      placeholder="Укажи комиссию из назначения платежа"
      inputProps={{ disabled: allDisabled || formFields?.commission?.disabled }}
    />
  );
};

export const CommissionField = observer(CommissionFieldComponent);
