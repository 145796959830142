export const findFirstInTree = <T>(
  nodes: T[],
  predicate: (node: T) => boolean,
  childKey: keyof T
): T | undefined => {
  for (const node of nodes) {
    if (predicate(node)) {
      return node;
    }
    const children = node[childKey] as unknown as T[] | undefined;
    if (Array.isArray(children)) {
      const result = findFirstInTree(children, predicate, childKey);
      if (result) return result;
    }
  }

  return undefined;
};
