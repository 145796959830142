import { observer } from "mobx-react-lite";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import SelectField from "ui-new/select";
import { debounce } from "lodash";
import { Spin } from "antd";
import { toJS } from "mobx";

const BUFFER_DISTANCE = 500;

interface ContragentProps {
  allDisabled: boolean;
}

const ContragentComponent: FC<ContragentProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: {
        act,
        advanceReport,
        docType,
        contragents,
        fetchGetContragents,
        contragentsIsLoading,
        contragentsMeta,
      },
    },
  } = useStore();
  const [searchTitle, setSearchTitle] = useState("");
  const debounceSearch = debounce((val: string) => {
    setSearchTitle(val);
    fetchGetContragents(val, 1);
  }, 300);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (scrollHeight - scrollTop - clientHeight <= BUFFER_DISTANCE && !contragentsIsLoading) {
        fetchGetContragents(searchTitle, (contragentsMeta?.current_page ?? 0) + 1);
      }
    },
    [contragentsIsLoading, contragentsMeta?.current_page, fetchGetContragents, searchTitle]
  );

  const { control } = useFormContext<{
    contragent_id: number;
  }>();

  const contragentsData = useMemo(() => {
    if (docType === "act_service" && !act?.contragent_id) return contragents;
    if (docType === "advance_report_service" && !advanceReport?.contragent_id) return contragents;

    const currentDoc =
      docType === "act_service" ? act : docType === "advance_report_service" ? advanceReport : null;

    if (!currentDoc) return contragents;

    const haveContragent = contragents.some(c => currentDoc.contragent_id === c.id);
    if (haveContragent) return contragents;

    return [
      ...toJS(contragents),
      {
        id: currentDoc.contragent_id,
        title: currentDoc.contragent,
      },
    ];
  }, [act, advanceReport, contragents, docType]);

  return (
    <SelectField
      label={"Контрагент"}
      control={control}
      name={"contragent_id"}
      options={contragentsData}
      selectProps={{
        disabled: allDisabled,
        showSearch: true,
        optionFilterProp: "title",
        onSearch: debounceSearch,
        onPopupScroll: handleScroll,
        loading: contragentsIsLoading,
        placeholder: `Выбери контрагента ${
          docType === "advance_report_service" ? "(если необходимо)" : ""
        }`,
        fieldNames: {
          label: "title",
          value: "id",
        },
        dropdownRender: menu => (
          <>
            {menu}
            {contragentsIsLoading && (
              <div style={{ textAlign: "center", padding: "8px" }}>
                <Spin size="small" />
              </div>
            )}
          </>
        ),
      }}
    />
  );
};

export const ContragentField = observer(ContragentComponent);
