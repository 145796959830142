import { observer } from "mobx-react-lite";
import { FC } from "react";
import cls from "./styles.module.scss";

interface IIKODeductionHeaderProps {
  onCancel: () => void;
  onSave: () => void;
}

const IIKODeductionHeaderComponent: FC<IIKODeductionHeaderProps> = ({ onCancel, onSave }) => {
  return (
    <div className={cls["deduction__header"]}>
      <div className={cls["deduction__header-container"]}>
        <h2>Рассчитать удержания из iiko</h2>
        <div className={cls["deduction__buttons"]}>
          <button onClick={onCancel} className={cls["button-secondary"]}>
            Отменить
          </button>
          <button className={cls["button-primary"]} onClick={onSave}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export const IIKODeductionHeader = observer(IIKODeductionHeaderComponent);
