import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from './styles.module.scss';
import { observer } from "mobx-react-lite";
import PnlReportTable from './components/pnl-report-table';
import './styles.scss';
import { MonthSelectorSeparated } from "widgets/month-selector/separated";

const PnlReportView = () => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [previousDate, setPreviousDate] = useState<null | Date>(new Date());
    const setNewDate = async (event: Date) => {
        setPreviousDate(currentDate);
        setCurrentDate(event);
    }

  return (
    <div className={styles.pageWrapper}>
        <header className={styles.header}>
            <h1 className={styles.headerText}>Отчет о прибыли и убытках</h1>

            {/* <div className="report-container">
                <PnlReportHeader />
                <PnlReportTable />
            </div> */}
        </header>
        <div className={styles.monthContainer}>
            <MonthSelectorSeparated selectedDate={currentDate} setSelectedDate={setNewDate} withMonth={false} />
        </div>

        <PnlReportTable date={currentDate} />
    </div>
  );
};

export const PnlReportPage = observer(PnlReportView);