import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import SelectField from "ui-new/select";

interface ResponsibleUserIdFieldProps {
  allDisabled: boolean;
}

const ResponsibleUserIdFieldComponent: FC<ResponsibleUserIdFieldProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: { employees },
    },
  } = useStore();
  const { control } = useFormContext<{
    user_id: number;
  }>();

  const employeesData = useMemo(
    () => employees.map(e => ({ value: e.id, label: `${e.surname} ${e.name}` })),
    [employees]
  );

  return (
    <SelectField
      label={"Подотчетное лицо"}
      control={control}
      name={"user_id"}
      options={employeesData}
      selectProps={{
        disabled: allDisabled,
        showSearch: true,
        placeholder: "Выбери подотчетное лицо",
      }}
    />
  );
};

export const ResponsibleUserIdField = observer(ResponsibleUserIdFieldComponent);
