import { FC } from "react";
import { Textarea } from "ui-new/textarea";

const onInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  if (e.target.value.length > 255) {
    e.target.value = e.target.value.slice(0, 255);
  }
};

interface CommentFieldProps {
  allDisabled?: boolean;
}

const CommentFieldComponent: FC<CommentFieldProps> = ({ allDisabled }) => {
  return (
    <Textarea
      name="comment"
      label="Комментарий"
      placeholder="Введи комментарий"
      rows={4}
      textareaProps={{ disabled: allDisabled, onInput }}
    />
  );
};

export const CommentField = CommentFieldComponent;
