import {api} from "./api";
import type {StockReport} from "../types/stock";

export const BASE_API_URL_V3 = `${process.env.REACT_APP_API_BASE}/api/v3/`;
export const BASE_API_CONFIG = { baseURL: BASE_API_URL_V3 };

export async function getStocksReport(body: any = []): Promise<StockReport[] | null> {
    try {
        const response = await api.post('/stock/stockReport', { types: body }, BASE_API_CONFIG);
        return response.data.data;
    } catch {
        return null;
    }
}

export async function getNomenclatureFilterTypes() {
    try {
        const response = await api.get('/directories/nomenclatures/types', BASE_API_CONFIG);
        return response.data.data;
    } catch {
        return null;
    }
}

export async function stockLoad() {
    return await api.post('/stock/stockLoad', {}, BASE_API_CONFIG);
}

export async function checkStocks() {
    try {
        const response = await api.post<{ success: boolean }>('/stock/canStockLoad', {}, BASE_API_CONFIG);
        return response.data;
    } catch {
        return null;
    }
}