import { FC, useCallback, useMemo } from "react";
import cls from "../styles.module.scss";
import { addSpaces } from "assets/utils";
import { FieldTypes, IBonusTableItem, IUser } from "../types";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

interface EmployeesTableProps {
  className?: string;

  title: string;
  bonusList: IBonusTableItem[];
}

const EmployeesTableComponent: FC<EmployeesTableProps> = ({ className, bonusList, title }) => {
  const {
    RootStore: {
      SalaryFundStore: {
        setBonusModalIsOpen: setEditPayIsOpen,
        setSelectedEmployee,
        setSelectedBonus,
        netOrGross,
        currentBonusStatus,
      },
    },
  } = useStore();
  const onRowClick = useCallback(
    (bonus: IBonusTableItem, user?: IUser["id"]) => {
      setSelectedEmployee(user);
      setSelectedBonus(bonus);
    },
    [setSelectedBonus, setSelectedEmployee]
  );
  const onFieldClick = useCallback(
    (field: FieldTypes) => {
      setEditPayIsOpen(true, field);
    },
    [setEditPayIsOpen]
  );

  const sum = useMemo(() => {
    let planSum = 0;
    let factSum = 0;
    let approveSum = 0;
    bonusList.forEach(val => {
      planSum += val[`plan_${netOrGross}`] ?? 0;
      factSum += val[`fact_${netOrGross}`] ?? 0;
      approveSum += val[`approved_${netOrGross}`] ?? 0;
    });
    planSum = Math.floor(planSum * 100) / 100;
    factSum = Math.floor(factSum * 100) / 100;
    approveSum = Math.floor(approveSum * 100) / 100;
    return [planSum, factSum, approveSum];
  }, [bonusList, netOrGross]);

  const sumData = useMemo(
    () =>
      sum.map((s, i) => {
        let isGray = false;
        if (i === 0 && currentBonusStatus?.value === 1) isGray = true;
        if ([1, 2].includes(i) && (currentBonusStatus?.value ?? 0) < 3) isGray = true;
        if (i === 3 && (currentBonusStatus?.value ?? 0) < 4) isGray = true;

        return (
          <td className={cls["td"]} style={isGray ? { color: "#999CA6" } : {}}>
            {addSpaces(s)}
          </td>
        );
      }),
    [currentBonusStatus?.value, sum]
  );

  const employeesData = useMemo(
    () =>
      bonusList.map((bonus, i) => {
        const { user, user_id, job } = bonus;
        const currentJob = title === "Хелперы" ? "Хэлпер" : job;
        return (
          <tr onClick={() => onRowClick(bonus, user_id)}>
            <td className={`${cls["td"]} ${cls["employee"]} ${cls["num"]}`}>{i + 1}</td>
            <td className={`${cls["td"]} ${cls["employee"]}`}>
              <span title={user}>{user}</span>
            </td>
            <td className={`${cls["td"]} ${cls["employee"]}`}>
              <span title={currentJob}>{currentJob}</span>
            </td>
            <td
              className={`${cls["td"]} ${cls["clickable"]}`}
              style={currentBonusStatus?.value === 1 ? { color: "#999CA6" } : {}}
              onClick={() => onFieldClick(FieldTypes["plan"])}
            >
              {addSpaces(bonus[`plan_${netOrGross}`] ?? 0)}
            </td>
            <td
              className={`${cls["td"]} ${cls["clickable"]}`}
              style={(currentBonusStatus?.value ?? 0) < 3 ? { color: "#999CA6" } : {}}
              onClick={() => onFieldClick(FieldTypes["fact"])}
            >
              {addSpaces(bonus[`fact_${netOrGross}`] ?? 0)}
            </td>
            <td
              className={`${cls["td"]} ${cls["clickable"]}`}
              style={(currentBonusStatus?.value ?? 0) < 3 ? { color: "#999CA6" } : {}}
              onClick={() => onFieldClick(FieldTypes["approved"])}
            >
              {addSpaces(bonus[`approved_${netOrGross}`] ?? 0)}
            </td>
          </tr>
        );
      }),
    [bonusList, currentBonusStatus?.value, netOrGross, onFieldClick, onRowClick, title]
  );

  return (
    <table className={`${cls["table"]} ${className ?? ""}`}>
      <colgroup>
        <col width={44} />
        <col width={207} />
        <col width={181} />
        <col width={177} />
        <col width={175} />
        <col width={177} />
      </colgroup>
      <thead className={`${cls["thead"]} ${cls["rounding"]}`}>
        <tr className={cls["tr"]}>
          <th className={cls["th"]} colSpan={3}>
            {title}
          </th>
          <th className={cls["th"]} colSpan={3}>
            Премия, ₽
          </th>
        </tr>
        <tr className={cls["tr"]}>
          <th className={cls["th"]}>№</th>
          <th className={cls["th"]}>Работник</th>
          <th className={cls["th"]}>Должность</th>
          <th className={cls["th"]}>План</th>
          <th className={cls["th"]}>Факт</th>
          <th className={cls["th"]}>Утверждено</th>
        </tr>
      </thead>
      <tfoot className={cls["tfoot"]}>
        <tr className={cls["tr"]}>
          <td className={cls["td"]} colSpan={3}>
            Всего
          </td>
          {sumData}
        </tr>
      </tfoot>
      <tbody className={cls["tbody"]}>{employeesData}</tbody>
    </table>
  );
};

export const EmployeesTable = observer(EmployeesTableComponent);
