import { useEffect } from "react";
import { BalanceSection } from "./components/section";
import { ReportsSkeleton } from "ui/skeletons/index";
import { MonthPicker } from "widgets/datepickers/month-picker";
import { observer } from "mobx-react-lite";
import { dateToFullRuMonthName } from "assets/utils";
import { useStore } from "store";
import NoData from "../../../ui/no-data/noData";

export const Balance = observer(() => {
  const {
    RootStore: {
      BalanceStore: {
        dataBAL,
        isLoadingBAL,
        dateBAL,
        handleDateChangeBAL,
        getDataBAL,
      },
    },
  } = useStore();

  useEffect(() => {
    getDataBAL();
  }, []);

  return (
    <>
      <div className="balance__datepicker-wrapper" data-testid={"balance"}>
        <MonthPicker date={dateBAL} dateSetter={handleDateChangeBAL} />
      </div>

      {isLoadingBAL ? (
        <ReportsSkeleton />
      ) : (
        <>
          <div className="reports__main">
            <div className="reportTable__header captionRegular colorNeutral50">
              <div className="reportTable__headerBalance reportTable__headerBalance_left">
                <div className="nominations__title">Наименование</div>
              </div>
              <div className="reportTable__headerBalance reportTable__headerBalance_right">
                <div className="summary__title">
                  {`на ${dateToFullRuMonthName(dateBAL).toLowerCase()}`}
                </div>
              </div>
            </div>
            <div className="reportTable__main">
              {dataBAL !== null ? (
                <>
                  {dataBAL.active && dataBAL.active.length > 0 && (
                    <BalanceSection
                      sectionData={dataBAL.active}
                      active={true}
                      sectionTotal={dataBAL.active_total}
                    />
                  )}
                  {dataBAL.passive && dataBAL.passive.length > 0 && (
                    <BalanceSection
                      sectionData={dataBAL.passive}
                      active={false}
                      sectionTotal={dataBAL.passive_total}
                    />
                  )}
                  <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{ textAlign: 'left', fontWeight: 'bold', paddingLeft: '20px', paddingTop: '20px'}}>Дельта Баланса</div>
                    <div style={{ textAlign: 'right', paddingRight: '20px', paddingTop: '20px'}}>{(dataBAL.active_total - dataBAL.passive_total).toFixed(2)}</div>
                 </div>
                </>
              ) : (
                <NoData />
              )}
            </div>
          </div>
          {/* Добавленный div с двумя строками */}
          
        </>
      )}
    </>
  );
});
