import { Dropdown, Menu, Checkbox, Space } from "antd";
import { CloseFilterIcon, FilterArrowIcon } from "pages/money-transactions/icons";
import { useStore } from "store";
import { MenuInfo } from "rc-menu/lib/interface";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { orderBy } from "lodash";

const PayTypeFilterComponent = () => {
  const {
    RootStore: {
      MoneyTransactionsStore: { payTypes, filterPayTypes, setFilterPayTypes, togglePayType },
    },
  } = useStore();

  const payTypesData = useMemo(
    () =>
      orderBy(payTypes, [pT => pT.title], ["asc"]).map(item => (
        <Menu.Item key={item.id}>
          <Checkbox checked={filterPayTypes.includes(item.id)}></Checkbox>
          {item.title}
        </Menu.Item>
      )),
    [filterPayTypes, payTypes]
  );

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <Menu
          onClick={(e: MenuInfo) => {
            const payType = payTypes[+e.key - 1];
            togglePayType(payType.id);
          }}
        >
          {payTypesData}
        </Menu>
      )}
    >
      <Space className="cursor-pointer" style={{ gap: "4px" }}>
        {
          <>
            Вид платежа
            {filterPayTypes.length > 0 ? (
              <CloseFilterIcon onClick={() => setFilterPayTypes([])} />
            ) : (
              <FilterArrowIcon />
            )}
          </>
        }
      </Space>
    </Dropdown>
  );
};

export const PayTypeFilter = observer(PayTypeFilterComponent);
