import { api, IBaseResponse } from "./api";

export async function fetchDashboardData(dateStart: string, dateEnd: string) {
  try {
    const response = await api.post(`dashboard/info`, {
      dateStart,
      dateEnd,
    });
    return response;
  } catch (e) {
    return null;
  }
}

interface IBillsResponse {
  bills: {
    period: string[];
    average_result: number[];
    count_result: number[];
  };

  by_report: {
    average: number;
    count: number;
  };
}

//Новая версия
interface IRevenueResponse {
  table: {
      revenue: {
          data: {
              date: string;              // Дата
              planning_revenue: number;  // Плановая выручка
              fact_revenue: number;      // Фактическая выручка
              percent: number;           // Процент отклонения
              nonfiscRevenue: number;    // Нефискальная выручка
          }[];
          results: {
              plan_sum: number;     // Суммарная плановая выручка
              fact_sum: number;     // Суммарная фактическая выручка
              percent: number;      // Процент отклонения
              nonfisc_sum: number;  // Суммарная нефискальная выручка
          };
      };
      receipts: {
          data: {
              date: string;          // Дата
              count_plan: number;    // Плановое количество чеков
              count_fact: number;    // Фактическое количество чеков
              average_plan: number;  // Плановый средний чек
              average_fact: number;  // Фактический средний чек
          }[];
          results: {
              count_plan: number;    // Суммарное плановое количество чеков
              count_fact: number;    // Суммарное фактическое количество чеков
              average_plan: number;  // Средний плановый чек
              average_fact: number;  // Средний фактический чек
          };
      };
  };
}

//YYYY-MM-DD
export async function getBills(day?: string | null) {
  try {
    const response = await api.post<IBaseResponse<IBillsResponse>>(
      `${process.env.REACT_APP_API_BASE}/api/v3/dashboard/bills/get`,
      { day }
    );

    console.log(response);

    return response.data.data;
  } catch (error) {
    return null;
  }
}

// Новая версияя
export async function getRevenue(month?: string | null) {
  try {
      const response = await api.post<IBaseResponse<IRevenueResponse>>(
          `${process.env.REACT_APP_API_BASE}/api/v3/dashboard/v2/get`,
          { month }
      );

      console.log(response);

      // Возвращаем данные таблицы
      return { 
        revenue: response.data.data.table.revenue.data,
        revenueTotal: response.data.data.table.revenue.results,
        receipts: response.data.data.table.receipts.data,
        receiptsTotal: response.data.data.table.receipts.results
      }
  } catch (error) {
      console.error("Error fetching revenue data:", error);
      return null;
  }
}

