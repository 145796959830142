import { useStore } from "store";
import { EInputStyleVariant, EInputVariant, Input } from "ui/input";
import React, { useEffect, useState } from "react";

let timer: number;

export const SearchInput = () => {
  const { RootStore } = useStore();
  const { MoneyTransactionsStore: BankStatementsStore } = RootStore;
  const { searchText, setSearchText } = BankStatementsStore;

  const [inputValue, setInputValue] = useState(searchText);

  useEffect(() => {
    timer = window.setTimeout(() => setSearchText(inputValue), 300);
    return () => window.clearTimeout(timer);
  }, [inputValue]);

  function handleSearchChange(event: React.FormEvent<HTMLInputElement>) {
    const { value } = event.target as HTMLInputElement;
    setInputValue(value);
  }

  return (
    <div className="statements__search">
      <Input
        type={EInputVariant.text}
        variant={EInputStyleVariant.searchInput}
        height={"32px"}
        maxWidth={"275px"}
        width={"230px"}
        borderRadius={"10px"}
        placeholder={"№ документа, сумма, ИНН"}
        withReset={true}
        value={inputValue}
        onChange={e => handleSearchChange(e)}
        onReset={() => {
          setSearchText("");
          setInputValue("");
        }}
      />
    </div>
  );
};
