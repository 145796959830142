import { makeAutoObservable } from "mobx";
import { getBills, getRevenue } from "api";
import { RootStore } from "../../store/rootStore";

export class DashboardStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  isLoading: boolean = false;
  billsCount: number = 0;
  billAverage: number = 0;

  period: string[] = [];
  averageResult: number[] = [];
  countResult: number[] = [];
  data: { count: number; average: number; period: string }[] = [];

  revenue_data: {
    date: string;        // Дата
    planning_revenue: number; // Плановая выручка
    fact_revenue: number;     // Фактическая выручка
    percent: number;         // Процент отклонения
    nonfiscRevenue: number;   // Нефискальная выручка
  }[] = [];
  revenue_total: {
    plan_sum: number;
    fact_sum: number;
    percent: number;
    nonfisc_sum: number;
  } = {
    plan_sum: 0,
    fact_sum: 0,
    percent: 0,
    nonfisc_sum: 0
  }

  receipts_data: {
    date: string;        // Дата
    count_plan: number; // Плановое кол-во чеков
    count_fact: number;     // Фактическое кол-во чеков
    average_plan: number;         // Плановый средний чек, руб.
    average_fact: number;   // Фактический средний чек, руб.
  }[] = [];
  receipts_total: {
    count_plan: number;
    count_fact: number;
    average_plan: number;
    average_fact: number;
  } = {
    count_plan: 0,
    count_fact: 0,
    average_plan: 0,
    average_fact: 0
  }
  

  setIsLoading = (bool: boolean) => {
    this.isLoading = bool;
  };

  fetchGetBills = async (day?: string | null) => {
    this.setIsLoading(true);
    const response = await getBills(day);
    this.setIsLoading(false);
    if (!response) {
      this.period = [];
      this.countResult = [];
      this.averageResult = [];
      this.billAverage = 0;
      this.billsCount = 0;
      this.data = [];
      return;
    }

    this.period = response.bills.period;
    this.countResult = response.bills.count_result;
    this.averageResult = response.bills.average_result;
    this.billAverage = response.by_report.average;
    this.billsCount = response.by_report.count;
    this.data = response.bills.period.map((p, i) => {
      return {
        period: p,
        average: this.averageResult[i] ?? 0,
        count: this.countResult[i] ?? 0,
      };
    });
  };

  fetchGetReveneu = async (month?: string | null) => {
    this.setIsLoading(true);
    const response = await getRevenue(month);
    this.setIsLoading(false);
    if (!response) {
      this.revenue_data = [];
      return;
    }

    this.revenue_data = response.revenue;
    this.revenue_total = response.revenueTotal;
    this.receipts_data = response.receipts;
    this.receipts_total = response.receiptsTotal
  };
}
