import {
  checkCreateInventory,
  createInventory,
  getArticles,
  getContragents,
  getDealNumbers,
  getDocumentTypes,
  getOperations,
  getResponsible,
  updateCashOrder,
} from "api";
import { makeAutoObservable, toJS } from "mobx";
import {
  ICategories,
  IContragent,
  IFilterDocumentType,
  IResponsible,
  Operation,
  RegSortOrder,
  RegSortType,
} from "./types";
import { Value } from "react-calendar/src/shared/types";
import { sortRegistryDocument } from "./utils";
import { showAlertPopup } from "../../ui/alert";
import { makePersistable } from "mobx-persist-store";

export class RegistryStore {
  constructor(rootStore: any) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "RegistryStore",
      properties: [
        "contragentsList",
        "responsibleList",
        "articlesList",
        "dealList",
        "pickedRegistryContragentTitle",
        "pickedRegistryResponsibleTitle",
      ],
      storage: window.localStorage,
    });
    this.rootStore = rootStore;
  }

  rootStore;
  operationsList: Operation[] = [];
  operationsDraftList: Operation[] = [];

  currentPage: number = 1;
  maxOperationsNumber: number = 10;

  setCurrentPage = (val: number) => {
    this.currentPage = val;
  };

  setMaxOperationsNumber = (val: number) => {
    this.maxOperationsNumber = val;
  };

  searchValue: string | null = null;
  firstItem: number = 1;
  lastItem: number = 1;

  setFirstItem = (val: number) => {
    console.log(val);
    this.firstItem = val;
  };
  setLastItem = (val: number) => {
    this.lastItem = val;
  };
  setSearchValue = (val: string | null) => {
    this.searchValue = val;
  };
  handleSearch = (event: any) => {
    const { value } = event.target as HTMLInputElement;
    this.setSearchValue(value === "" ? null : value);

    if (value !== "" && this.operationsList) {
      this.setSearchList(
        toJS(this.operationsList).filter((item: any) => {
          const documentSplit = item.document.split("№");
          const documentValue = documentSplit.length > 1 ? documentSplit[1] : "";
          const inn = item.inn ? item.inn : "";

          return (
            documentValue.includes(value) ||
            String(item.sum_debet).includes(value) ||
            String(inn).includes(value)
          );
        })
      );
    } else {
      this.setSearchList(this.operationsList);
    }
  };

  setOperationsList = (arr: Operation[]) => {
    this.operationsList = arr;
    this.setOperationsDraftList(arr);
  };
  setOperationsDraftList = (arr: Operation[]) => {
    this.operationsDraftList = arr;
  };
  updateOperationsList = (arr: Operation[]) => {
    this.operationsList = arr;
  };

  // новое, раньше было в общем сторе для других модулей
  pickedDocument: any;
  setPickedDocument = (doc: any) => {
    this.pickedDocument = doc;
  };
  pickedStatementsDocumentId: any;
  setPickedStatementsDocumentId = (newId: any) => {
    this.pickedStatementsDocumentId = newId;
  };
  pickedStatementsContragentId: any;
  setPickedStatementsContragentId = (newId: any) => {
    this.pickedStatementsContragentId = newId;
  };

  //// filters
  pickedRegistryPeriod: Date[] = [];
  pickedRegistryStartDate: string | null = null;
  pickedRegistryEndDate: string | null = null;
  pickedRegistryStatus: string[] = [];
  pickedRegistryStatusTitle: string[] = [];
  pickedRegistryDocumentTitle: string[] = [];
  pickedRegistryDocument: string[] = [];
  pickedRegistryContragentTitle: string[] = [];
  pickedRegistryUserContragent: string[] = [];
  pickedRegistryContragent: number[] = [];
  pickedRegistryResponsibleTitle: string[] = [];
  pickedRegistryResponsible: number[] = [];

  setPickedRegistryPeriod = (arr: Date[]) => {
    this.pickedRegistryPeriod = arr;
  };
  setPickedRegistryStartDate = (str: string | null) => {
    this.pickedRegistryStartDate = str;
  };
  setPickedRegistryEndDate = (srt: string | null) => {
    this.pickedRegistryEndDate = srt;
  };
  setPickedRegistryStatus = (srt: string[]) => {
    this.pickedRegistryStatus = srt;
  };
  setPickedRegistryStatusTitle = (srt: string[]) => {
    this.pickedRegistryStatusTitle = srt;
  };
  setPickedRegistryDocumentTitle = (srt: string[]) => {
    this.pickedRegistryDocumentTitle = srt;
  };
  setPickedRegistryDocument = (srt: string[]) => {
    this.pickedRegistryDocument = srt;
  };
  setPickedRegistryContragentTitle = (arr: string[]) => {
    this.pickedRegistryContragentTitle = arr;
  };
  setPickedRegistryUserContragent = (arr: string[]) => {
    this.pickedRegistryUserContragent = arr;
  };
  setPickedRegistryContragent = (arr: number[]) => {
    this.pickedRegistryContragent = arr;
  };
  setPickedRegistryResponsibleTitle = (arr: string[]) => {
    this.pickedRegistryResponsibleTitle = arr;
  };
  setPickedRegistryResponsible = (arr: number[]) => {
    this.pickedRegistryResponsible = arr;
  };
  //// sort
  pickedSortOrder: RegSortOrder | null = null;
  pickedSortType: RegSortType | null = null;

  setPickedSortOrder = (val: RegSortOrder) => {
    this.pickedSortOrder = val;
  };
  setPickedSortType = (val: RegSortType) => {
    this.pickedSortType = val;
  };

  get registryDocumentsList(): Operation[] | undefined {
    return sortRegistryDocument(
      this.searchValue ? this.searchList : this.operationsList,
      this.pickedSortOrder,
      this.pickedSortType
    );
  }

  toggleSort = (option: RegSortType) => {
    if (option === this.pickedSortType) {
      this.setPickedSortOrder(
        this.pickedSortOrder === RegSortOrder.asc ? RegSortOrder.desc : RegSortOrder.asc
      );
    } else {
      this.setPickedSortType(option);
      this.setPickedSortOrder(RegSortOrder.asc);
    }
  };

  ////api
  isRegistryLoading: boolean = false;
  contragentsList: IContragent[] = [];
  responsibleList: IResponsible[] = [];
  articlesList = { in: [], out: [] };
  dealList = [];
  addedDocumentType: number | null = null;
  pickedRegistryArticle: number | null = null;
  shownRegistryArticle: string = "Выберите статью";
  pickedRegistryCashContragent: string = "Выберите контрагента";
  pickedRegistryContragentId = "";
  pickedRegistryDeal: string = "Выберите №";
  pickedRegistryDealId: string | null = null;
  pickedContragentId: any;
  registrySum: number = 0;
  registryComment: string | null = null;
  firstRegistryModalIsOpen: boolean = false;
  secondRegistryModalIsOpen: boolean = false;
  checkRegistryIsLoading: boolean = false;
  errorRegistryModalIsOpen: boolean = false;
  errorRegistryMessage: string | null = null;
  listOfDocTypes: IFilterDocumentType[] | null = null;
  //! Не используется (24.05.24)
  // categoriesList: ICategories[] | null = null;
  pickedCategories: string[] = [];
  pickedResponsibleList: Array<IResponsible["id"]> = [];
  searchList: Operation[] = [];

  setIsRegistryLoading = (bool: boolean) => {
    this.isRegistryLoading = bool;
  };

  setContragentsList = (arr: any) => {
    this.contragentsList = arr;
  };
  setResponsibleList = (arr: any) => {
    this.responsibleList = arr;
  };
  setArticlesList = (obj: any) => {
    this.articlesList = obj;
  };
  setDealList = (obj: any) => {
    this.dealList = obj;
  };
  setAddedDocumentType = (num: number | null) => {
    this.addedDocumentType = num;
  };
  setPickedRegistryArticle = (num: number | null) => {
    this.pickedRegistryArticle = num;
  };
  setShownRegistryArticle = (str: string) => {
    this.shownRegistryArticle = str;
  };
  setPickedRegistryCashContragent = (str: string) => {
    this.pickedRegistryCashContragent = str;
  };
  setPickedRegistryContragentId = (str: string) => {
    this.pickedContragentId = str;
  };
  setPickedRegistryDeal = (str: string) => {
    this.pickedRegistryDeal = str;
  };
  setPickedRegistryDealId = (str: string | null) => {
    this.pickedRegistryDealId = str;
  };
  setRegistrySum = (num: number) => {
    this.registrySum = num;
  };
  setRegistryComment = (str: string | null) => {
    this.registryComment = str;
  };
  setFirstRegistryModalIsOpen = (bool: boolean) => {
    this.firstRegistryModalIsOpen = bool;
  };
  setSecondRegistryModalIsOpen = (bool: boolean) => {
    this.secondRegistryModalIsOpen = bool;
  };
  setCheckRegistryIsLoading = (bool: boolean) => {
    this.checkRegistryIsLoading = bool;
  };
  setErrorRegistryModalIsOpen = (bool: boolean) => {
    this.errorRegistryModalIsOpen = bool;
    if (!bool) this.errorRegistryMessage = null;
  };
  setListOfDocType = (val: IFilterDocumentType[]) => {
    this.listOfDocTypes = val;
  };
  setPickedCategories = (val: string[]) => {
    this.pickedCategories = val;
  };
  setPickedResponsible = (val: Array<IResponsible["id"]>) => {
    this.pickedResponsibleList = val;
  };
  togglePickedResponsibleId = (val: IResponsible["id"]) => {
    const pickedIndex = this.pickedResponsibleList.indexOf(val);

    if (pickedIndex < 0) {
      this.pickedResponsibleList.push(val);
    } else {
      this.pickedResponsibleList.splice(pickedIndex, 1);
    }
  };
  setSearchList = (arr: Operation[]) => {
    this.searchList = arr;
  };
  getFiltersParams = () => {
    return {
      contragents: this.contragentsList.filter(item => item.checked).map(item => item.id),
      responsible: this.responsibleList.filter(item => item.checked).map(item => item.id),
    };
  };

  downloadOperations = async () => {
    this.setIsRegistryLoading(true);

    const { contragents, responsible } = this.getFiltersParams();

    const response = await getOperations(
      this.pickedRegistryStartDate,
      this.pickedRegistryEndDate,
      this.pickedRegistryStatus,
      this.pickedRegistryDocument,
      this.pickedRegistryUserContragent,
      contragents,
      responsible
    );

    if (response) {
      if (JSON.stringify(response.data.data) != JSON.stringify(toJS(this.operationsList))) {
        this.setOperationsList(response.data.data);
      }
    }
    this.setIsRegistryLoading(false);
  };

  fetchContragents = async () => {
    const response = await getContragents();

    if (response) {
      this.setContragentsList(response.map((item: any) => ({ ...item, checked: false })));
    }
  };
  fetchResponsible = async () => {
    const response = await getResponsible();
    if (response) {
      this.setResponsibleList(response.map((item: any) => ({ ...item, checked: false })));
    }
  };
  fetchArticles = async () => {
    const response = await getArticles();
    if (response) {
      this.setArticlesList(response);
    }
  };
  fetchDealNumbers = async () => {
    const response = await getDealNumbers();
    if (response) {
      this.setDealList(response);
    }
  };

  fetchCashOrderUpdate = async () => {
    const response = await updateCashOrder(
      this.addedDocumentType,
      this.pickedRegistryDealId,
      this.registrySum,
      this.registryComment,
      this.pickedRegistryArticle,
      this.pickedContragentId
    );

    this.setAddedDocumentType(null);
    this.setPickedRegistryDeal("Выберите №");
    this.setPickedRegistryCashContragent("Выберите контрагента");
    this.setRegistrySum(0);
    this.setRegistryComment(null);
    this.setPickedRegistryArticle(null);

    if (response.success) {
      this.downloadOperations();
    }
  };

  fetchDocumentTypes = async () => {
    const response = await getDocumentTypes();
    this.setListOfDocType(response);
  };

  //! Не используется (24.05.24)
  // fetchCategories = async () => {
  //   const response = await getCategories();
  //   if (response) {
  //     this.setCategoriesList(response);
  //   }
  // };

  fetchCheckCreateInventory = async () => {
    this.setCheckRegistryIsLoading(true);
    const response = await checkCreateInventory();
    this.setFirstRegistryModalIsOpen(false);
    this.setCheckRegistryIsLoading(false);
    if (response) {
      if (response.success) {
        this.setSecondRegistryModalIsOpen(true);
      } else {
        this.errorRegistryMessage = response.message ?? "Упс, что-то пошло не так";
        this.setErrorRegistryModalIsOpen(true);
      }
    }
  };

  fetchCreateInventory = async (date: Value) => {
    const response = await createInventory(
      (date as Date).toLocaleDateString("ru-RU"),
      this.pickedResponsibleList
    );
    if (response) {
      this.downloadOperations();
      showAlertPopup(
        "Заявка на инвентаризацию успешно создана!",
        "Заявка отправлена ответственному сотруднику",
        "success"
      );
    }
  };

  resetAllFilters = () => {
    this.setPickedRegistryPeriod([]);
    this.setPickedRegistryStartDate(null);
    this.setPickedRegistryEndDate(null);
    this.setPickedRegistryStatus([]);
    this.setPickedRegistryStatusTitle([]);
    this.setPickedRegistryDocument([]);
    this.setPickedRegistryDocumentTitle([]);
    this.setPickedRegistryContragentTitle([]);
    this.setPickedRegistryUserContragent([]);
    this.setPickedRegistryContragent([]);
    this.setPickedRegistryResponsibleTitle([]);
    this.setPickedRegistryResponsible([]);
    this.setContragentsList(this.contragentsList.map(item => ({ ...item, checked: false })));
  };

  get isResetActive() {
    return (
      this.pickedRegistryStartDate !== null ||
      toJS(this.pickedRegistryStatus).length > 0 ||
      toJS(this.pickedRegistryDocument).length > 0 ||
      toJS(this.pickedRegistryContragentTitle).length > 0 ||
      toJS(this.pickedRegistryResponsible).length > 0 ||
      toJS(this.contragentsList.some(item => item.checked))
    );
  }
}
