import React, { useState } from 'react';
import { PnlReportRowDataTest } from '../types';

interface PnlReportRowProps {
  data: PnlReportRowDataTest;
  isCategory?: boolean; // Флаг, является ли строка категорией
  isSubCategory?: boolean;
}
//TODO: Нужно зарефакторить модуль когда будет время.
const arr = Array.from(Array(12).keys());

const PnlReportRow: React.FC<PnlReportRowProps> = ({ data, isCategory, isSubCategory }) => {

  //Use states
  const [isExpanded, setIsExpanded] = useState(false);

  const rowStyle = { 
    backgroundColor: isExpanded ? '#F3EEFF' : (data.is_indicator ? '#f7f1ea': 'white'),
    fontWeight: isSubCategory ? "500" : "bold",
    verticalAlign: 'middle' 
  }

  const toggleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  const hasItems = data.items?.length > 0
  

  return (
    <>
      <tr
        className={isCategory ? 'category' : 'subcategory'}
        onClick={hasItems ? toggleExpand : undefined}
        style={{ cursor: hasItems ? 'pointer' : 'default' }}
      >
        <td className="title" style={rowStyle}>
            <span
              className={`expand-arrow ${isExpanded ? 'expanded' : '' }`}
              style={{ marginRight: '8px' }} // Отступ справа от стрелки
            >
              {hasItems && (isExpanded ? '−' : '>')}
            </span>
          {data.title}
        </td>
        {/* Проверяем наличие results перед вызовом .map */}
        {data.results && Array.isArray(data.results) ? (
          data.results.map((month, index) => (
            <React.Fragment key={index}>
              <td style={rowStyle}>{month.plan.toLocaleString()}</td>
              <td style={rowStyle}>{month.fact.toLocaleString()}</td>
            </React.Fragment>
          ))
        ) : (
          arr.map(() => 
            <React.Fragment>
              <td style={rowStyle}></td>
              <td style={rowStyle}></td>
            </React.Fragment>
          ) // Выводим сообщение, если данных нет
        )}
      </tr>
      {isExpanded &&
        Array.isArray(data.items) &&
        data.items.map((detail, detailIndex) => (
          
          <PnlReportRow 
            key={detailIndex} 
            data={detail} 
            isCategory={!detail.is_indicator}
             isSubCategory={true}
          />
        ))}
    </>
  );
};

export default PnlReportRow;
