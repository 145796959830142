import { PnlReportRowDataTest } from "pages/pnl-beta/types";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { onError } from "./schedulePlanning";

// Функция для загрузки данных
export const getPnlData = async (period: string) => {
  try {
    const response = await api.post<PnlReportRowDataTest[]>(`reports/pnl/get`, {
      period,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return onError(error);
  }
};
