import { observer } from "mobx-react-lite";
import { FC, useCallback, useMemo, useState } from "react";
import cls from "../styles.module.scss";
import { useNavigate } from "react-router";
import { BackButton, BucketIcon } from "../../icons";
import { useStore } from "store";
import { DocumentStatus } from "../components/document-status";
import { showConfirmModal } from "ui-new/alert/confirm";
import { DeleteModal } from "../components/delete-modal";

interface TransactionHeaderProps {
  onSave: () => void;
  onApprove: () => void;
  isCreate: boolean;
}

const ActServiceHeaderComponent: FC<TransactionHeaderProps> = ({ isCreate, onSave, onApprove }) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: { fetchDeleteStatus, act },
    },
  } = useStore();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const navigate = useNavigate();

  const onDeleteHandler = useCallback(
    (comment: string) => {
      fetchDeleteStatus(comment, "act_service").then(() => navigate(".."));
    },
    [fetchDeleteStatus, navigate]
  );

  const buttonsData = useMemo(() => {
    if (isCreate) {
      return (
        <div className={cls["buttons-group"]}>
          <button className="button-primary" onClick={onSave}>
            Сохранить документ
          </button>
        </div>
      );
    }

    if (act?.status === "deleted") return <DocumentStatus status={act.status} />;
    if (act?.status === "approved") return <DocumentStatus status={act.status} />;
    // return (
    //   <div className={cls["buttons-group"]}>
    //     <button className="button-secondary" onClick={onApprove}>
    //       Распровести
    //     </button>
    //   </div>
    // );
    if (act?.status === "pending")
      return (
        <div className={cls["buttons-group"]}>
          <button
            className={cls["transaction-delete__button"]}
            onClick={() => setDeleteModalIsOpen(true)}
          >
            <BucketIcon /> Удалить
          </button>
          <button className="button-secondary" onClick={onApprove}>
            Утвердить
          </button>
          <button className="button-primary" onClick={onSave}>
            Сохранить
          </button>
        </div>
      );
  }, [isCreate, onApprove, onDeleteHandler, onSave]);

  const title = useMemo(() => {
    if (isCreate) return "Создание документа: Акт по услугам";
    return "Акт по услугам";
  }, [isCreate]);

  const onBackButton = useCallback(() => {
    if (!act || act.status === "pending") {
      showConfirmModal({
        title: "Документ не будет сохранен",
        confirmButtonLabel: "Сохранить документ",
        cancelButtonLabel: "Выйти",
        onConfirm: () => {},
        onCancel: () => navigate(".."),
        onClose: () => {},
        minWidth: "424px",
        messages: [
          "Вы пытаетесь покинуть страницу, не сохранив изменения. Все несохраненные данные будут потеряны.",
        ],
      });
      return;
    }
    navigate("..");
  }, [act, navigate]);

  return (
    <>
      <div className={cls["header"]}>
        <div className={cls["breadcrumbs"]}>
          <p className={cls["grey"]} onClick={() => navigate("/main/registry/")}>
            Реестр документов /
          </p>
          <p className={cls["black"]}>Акт по услугам</p>
        </div>
        <div className={cls["title-container"]}>
          <div className={cls["text"]}>
            <div className={cls["backlink"]} onClick={onBackButton}>
              <BackButton />
            </div>
            <h2 className={cls["title"]}>{title}</h2>
          </div>
          {buttonsData}
        </div>
      </div>
      {deleteModalIsOpen && (
        <DeleteModal onClose={() => setDeleteModalIsOpen(false)} onSave={onDeleteHandler} />
      )}
    </>
  );
};

export const ActServiceHeader = observer(ActServiceHeaderComponent);
