import { FC, useEffect, useMemo } from "react";
import cls from "../styles.module.scss";
import {
  DateAndNumber,
  ContragentField,
  ResponsibleField,
  PnLField,
  SumField,
  PositionsTable,
} from "../components";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { DocumentStatusEnum } from "../types";
import { useFormContext } from "react-hook-form";

interface FormProps {}

const FormComponent: FC<FormProps> = () => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: { act, utilityArticle },
    },
  } = useStore();
  const allDisabled = useMemo(
    () =>
      ([DocumentStatusEnum.approved, DocumentStatusEnum.deleted] as any[]).includes(
        DocumentStatusEnum[act?.status ?? "pending"]
      ),
    [act?.status]
  );
  const { watch, setValue, getValues } = useFormContext();
  const pnlId = watch("pnl_article_id");
  const isUtility = typeof pnlId === "number" && pnlId === utilityArticle?.id;

  useEffect(() => {
    if (act?.pnl_article_id !== pnlId) return;

    if (pnlId === utilityArticle?.id && getValues("positions") === null) {
      setValue("positions", act?.positions);
      setValue("sum", null);
      return;
    }
    if (getValues("sum") === null) {
      setValue("sum", act?.positions[0].sum);
      setValue("positions", null);
    }
  }, [act?.pnl_article_id, act?.positions, getValues, pnlId, setValue, utilityArticle?.id]);

  return (
    <>
      <div className={cls["form-container"]}>
        <div className={cls["form-column"]}>
          <DateAndNumber allDisabled={allDisabled} />
          <PnLField allDisabled={allDisabled} />
          {!isUtility && <SumField allDisabled={allDisabled} />}
        </div>
        <div className={cls["form-column"]}>
          <ResponsibleField allDisabled={allDisabled} />
          <ContragentField allDisabled={allDisabled} />
        </div>
      </div>
      {isUtility && <PositionsTable allDisabled={allDisabled} />}
    </>
  );
};

export const Form = observer(FormComponent);
