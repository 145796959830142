import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo } from "react";
import { DocTable } from "./doc-table";
import { useStore } from "store";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormValues } from ".";

interface IIKODeductionTableProps {}

const IIKODeductionTablesComponent: FC<IIKODeductionTableProps> = () => {
  const {
    RootStore: {
      SalaryFundStore: { fetchGetEmployeesAndHelpers },
    },
  } = useStore();

  useEffect(() => {
    fetchGetEmployeesAndHelpers();
  }, []);

  const { control } = useFormContext<FormValues>();
  const { fields } = useFieldArray({ control: control, name: "writeoffs" });

  const tables = useMemo(
    () =>
      fields.map((writeoff, i) => (
        <DocTable key={writeoff.guid_1c} index={i} writeoff={writeoff} />
      )),
    [fields]
  );

  return <div>{tables}</div>;
};

export const IIKODeductionTables = observer(IIKODeductionTablesComponent);
