import {
  FieldTypes,
  IBepInfo,
  IBonusAward,
  IContract,
  IContractAward,
  IContractStatus,
  IGeneralAward,
  IHoldAndPaid,
  IHoldAndPaidTotal,
  IJobTitle,
  IManagerAward,
  IShiftAward,
  IStatus,
  IUser,
  IWriteoff,
} from "pages/salary-fund/types";
import { api } from "./api-new";
import { onError } from "./schedulePlanning";
import { IBaseResponse } from "./api";

export interface IGetAwards {
  helpers: IUser[];
  job_titles: IJobTitle[];
  statuses: IStatus[];
  users: IUser[];
  ndfl: number;

  message?: string;
}

export interface IGetAwardsResponse {
  awards: IGeneralAward[];
  helpers: IUser[];
  job_titles: IJobTitle[];
  users: IUser[];
  message?: string;
}

export interface IGetBonusesResponse extends IGetAwards {
  bonusAwards: IBonusAward[];
}

export interface IGetContractsResponse extends IGetAwards {
  contractAwards: IContractAward[];
  contracts: IContract[];
  contract_statuses: IContractStatus[];
}

export interface IGetShiftsResponse extends IGetAwards {
  shiftAwards: IShiftAward[];
}

export interface IGetManagerResponse extends IGetAwards {
  managerAwards: any[];
}

//YYYY-MM
export async function getAwards(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetAwardsResponse>>("awards/get", {
      params: { month },
    });

    return response.data.data;
  } catch (error) {}
}

export async function getBonuses(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetBonusesResponse>>(`awards/bonuses/get`, {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export interface IUpdateBonusParams {
  bonusAward: IBonusAward["id"];
  type: FieldTypes;
  sum: number;
}

export async function updateBonus(body: IUpdateBonusParams) {
  try {
    const response = await api.post<IBaseResponse<IGetBonusesResponse>>(
      `awards/bonuses/update`,
      body
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function planBonus(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetBonusesResponse>>("awards/bonuses/plan", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function approveBonus(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetBonusesResponse>>("awards/bonuses/approve", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function payBonus(month: string) {
  try {
    const response = await api.get<IBaseResponse<any>>("awards/bonuses/pay", {
      params: { month },
    });

    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function getContracts(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetContractsResponse>>("awards/contracts/get", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function setLastPeriodContracts(month: string) {
  try {
    const response = await api.get<IBaseResponse<any>>("awards/contracts/lastPeriod", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export interface ICreateContractProps {
  user_id: IUser["id"];
  date: string;
  title: string;
  plan_award: number | null;
  description: string | null;
}
export async function createContract(body: ICreateContractProps) {
  try {
    const response = await api.post<IBaseResponse<IGetContractsResponse>>(
      "user/contracts/create",
      body
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export interface IUpdateContractProps {
  id: IContract["id"];
  title: string;
  plan_award?: number | null;
  fact_award?: number | null;
  user_id: IUser["id"];
  description?: string | null;
  manager_comment?: string | null;
}

export async function updateContract(body: IUpdateContractProps) {
  try {
    const response = await api.post<IBaseResponse<IGetContractsResponse>>(
      "user/contracts/update",
      body
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function cancelContract(id: IContract["id"]) {
  try {
    const response = await api.post<IBaseResponse<IGetContractsResponse>>("user/contracts/cancel", {
      id,
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function approveContract(id: IContract["id"]) {
  try {
    const response = await api.post<IBaseResponse<IGetContractsResponse>>(
      "user/contracts/approve",
      { id }
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function planContractAward(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetContractsResponse>>("awards/contracts/plan", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function approveContractAward(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetContractsResponse>>(
      "awards/contracts/approve",
      {
        params: { month },
      }
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function payContractAward(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetContractsResponse>>("awards/contracts/pay", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function getShifts(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetShiftsResponse>>("awards/shifts/get", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function planShifts(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetShiftsResponse>>("awards/shifts/plan", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function approveShifts(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetShiftsResponse>>("awards/shifts/approve", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function payShifts(month: string) {
  try {
    const response = await api.get<IBaseResponse<any>>("awards/shifts/pay", {
      params: { month },
    });
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function getManager(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetManagerResponse>>("awards/manager/get", {
      params: { month },
    });

    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export interface IUpdateManagerParams {
  id: IManagerAward["id"];
  month: string;
  field: string;
  value: number;
}
export async function updateManager(body: IUpdateManagerParams) {
  try {
    const response = await api.post<IBaseResponse<IGetManagerResponse>>(
      "awards/manager/update",
      body
    );

    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function planManager(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetManagerResponse>>("awards/manager/plan", {
      params: { month },
    });

    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function approveManager(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetManagerResponse>>("awards/manager/approve", {
      params: { month },
    });

    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function payManager(month: string) {
  try {
    const response = await api.get<IBaseResponse<IGetManagerResponse>>("awards/manager/pay", {
      params: { month },
    });

    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export interface IGetDeductionsResponse {
  data: IHoldAndPaid[];
  total: IHoldAndPaidTotal;
}
//YYYY-MM
export async function getDeductions(month: string) {
  try {
    const response = await api.post<IBaseResponse<IGetDeductionsResponse>>(
      "awards/holdsAndPaids/get",
      {
        date: month,
      }
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function getByWriteoffs(date: string) {
  try {
    const response = await api.post<IBaseResponse<IWriteoff[]>>(
      "awards/holdsAndPaids/getByWriteoffs",
      {
        date,
      }
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function setByWriteoffs(body: { date: string; writeoffs: IWriteoff[] }) {
  try {
    const response = await api.post<IBaseResponse<IWriteoff[]>>(
      "awards/holdsAndPaids/setByWriteoffs",
      body
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function rewardByShortages(date: string) {
  try {
    const response = await api.post<IBaseResponse<IWriteoff[]>>(
      "awards/holdsAndPaids/rewardByShortages",
      {
        date,
      }
    );
    return response.data.data;
  } catch (error) {
    onError(error);
  }
}

export async function getBepBonuses(month: string) {
  try {
    const response = await api.get<IBepInfo>("awards/bep_bonuses/get", {
      params: { date: month },
    });

    return response.data;
  } catch (error) {
    onError(error);
  }
}

export async function planBepBonuses(month: string) {
  try {
    const response = await api.get<IBepInfo>("awards/bep_bonuses/plan", {
      params: { date: month },
    });

    return response.data;
  } catch (error) {
    onError(error);
  }
}

export async function approveBepBonuses(month: string) {
  try {
    const response = await api.get<IBepInfo>("awards/bep_bonuses/approve", {
      params: { date: month },
    });

    return response.data;
  } catch (error) {
    onError(error);
  }
}
