import { Dropdown, Menu, Checkbox, Space } from "antd";
import { CloseFilterIcon, FilterArrowIcon } from "pages/money-transactions/icons";
import { useStore } from "store";
import { MenuInfo } from "rc-menu/lib/interface";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { toJS } from "mobx";
import { MenuItemGroup } from "rc-menu";
import { orderBy } from "lodash";

const OperationTypeFilterComponent = () => {
  const {
    RootStore: {
      MoneyTransactionsStore: {
        operationTypes,
        moneyTransactionTypes,
        filterOperationTypes,
        setFilterOperationTypes,
        toggleOperationType,
      },
    },
  } = useStore();

  const operationTypesData = useMemo(() => {
    return orderBy(toJS(moneyTransactionTypes), [type => type.title], ["asc"]).map(type => {
      const operations = toJS(operationTypes).filter(
        oT => oT.money_transaction_type_id === type.id
      );
      return (
        <MenuItemGroup title={type.title}>
          {orderBy(operations, [oT => oT.title], ["asc"]).map(item => (
            <Menu.Item key={item.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <div>
                  <Checkbox checked={filterOperationTypes.includes(item.id)}></Checkbox>
                  {item.title}
                </div>
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "#999999",
                  }}
                >
                  {item.money_transaction_type_title}
                </span>
              </div>
            </Menu.Item>
          ))}
        </MenuItemGroup>
      );
    });
  }, [filterOperationTypes, moneyTransactionTypes, operationTypes]);

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <Menu
          style={{ height: "300px" }}
          onClick={(e: MenuInfo) => {
            const operationType = operationTypes[+e.key - 1];
            toggleOperationType(operationType.id);
          }}
        >
          {operationTypesData}
        </Menu>
      )}
    >
      <Space className="cursor-pointer" style={{ gap: "4px" }}>
        {filterOperationTypes.length > 0 ? (
          <>
            Вид операции
            <CloseFilterIcon onClick={() => setFilterOperationTypes([])} />
          </>
        ) : (
          <>
            Вид операции <FilterArrowIcon />{" "}
          </>
        )}
      </Space>
    </Dropdown>
  );
};

export const OperationTypeFilter = observer(OperationTypeFilterComponent);
