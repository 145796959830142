import { FC, useEffect, useMemo, useState } from "react";
import { MonthSelector } from "widgets/month-selector";
import cls from "../styles.module.scss";
import { Segmented } from "antd";
import { FundTabs, IStatus } from "../types";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { TableInfoMessage } from "./table-info-message";

interface FundHeaderProps {
  activeTab: FundTabs;
  setActiveTab: (tab: FundTabs) => void;
  onIIKODeduction: () => void;
}

const FundHeaderComponent: FC<FundHeaderProps> = ({ activeTab, setActiveTab, onIIKODeduction }) => {
  const [isDeduction, setIsDeduction] = useState(false);
  const {
    RootStore: {
      SalaryFundStore: {
        selectedDate,
        setSelectedDate,
        currentBonusStatus,
        currentShiftsStatus,
        managerCurrentStatus,
        currentContractStatus,
        bepStatus,
        netOrGross,
        setNetOrGross,
        planBonusConfirm,
        approveBonusConfirm,
        planShiftsConfirm,
        approveShiftsConfirm,
        approveManagersConfirm,
        planManagersConfirm,
        planContractAwardConfirm,
        approveContractAwardConfirm,
        planBepConfirm,
        approveBepConfirm,
        deductionsInfo,
        rewardByShortagesConfirm,
      },
    },
  } = useStore();
  const [currentStatus, setCurrentStatus] = useState<IStatus | null>(null);

  const [planClick, approveClick] = useMemo(() => {
    switch (activeTab) {
      case FundTabs["Премии"]:
        return [planBonusConfirm, approveBonusConfirm];
      case FundTabs["Вознаграждения за смены"]:
        return [planShiftsConfirm, approveShiftsConfirm];
      case FundTabs["Управляющий"]:
        return [planManagersConfirm, approveManagersConfirm];
      case FundTabs["Контракты"]:
        return [planContractAwardConfirm, approveContractAwardConfirm];
      case FundTabs["Премии ТБУ"]:
        return [planBepConfirm, approveBepConfirm];
      default:
        return [() => {}, () => {}, () => {}];
    }
  }, [
    activeTab,
    approveBepConfirm,
    approveBonusConfirm,
    approveContractAwardConfirm,
    approveManagersConfirm,
    approveShiftsConfirm,
    planBepConfirm,
    planBonusConfirm,
    planContractAwardConfirm,
    planManagersConfirm,
    planShiftsConfirm,
  ]);

  useEffect(() => {
    if (activeTab === FundTabs["Выплаты и удержания"]) {
      setIsDeduction(true);
      return;
    }
    setIsDeduction(false);
  }, [activeTab]);

  useEffect(() => {
    setCurrentStatus(prev => {
      if (activeTab === FundTabs["Премии"]) return currentBonusStatus;
      if (activeTab === FundTabs["Вознаграждения за смены"]) return currentShiftsStatus;
      if (activeTab === FundTabs["Контракты"]) return currentContractStatus;
      if (activeTab === FundTabs["Управляющий"]) return managerCurrentStatus;
      if (activeTab === FundTabs["Премии ТБУ"]) return bepStatus;
      return null;
    });
  }, [
    activeTab,
    bepStatus,
    currentBonusStatus,
    currentContractStatus,
    currentShiftsStatus,
    managerCurrentStatus,
  ]);

  const approveDisabledByDate = useMemo(() => {
    const now = dayjs(new Date());
    if (currentStatus?.value === 8) {
      const firstPeriod = dayjs(selectedDate).set("date", 15);
      const isAfter = firstPeriod.isAfter(now, "D");
      if (isAfter) return true;
    }
    if (currentStatus?.value === 7) {
      const secondPeriod = dayjs(selectedDate).add(1, "M").set("D", 1);

      const isAfter = secondPeriod.isAfter(now, "D");
      const isSame = secondPeriod.isSame(now, "D");
      if (isAfter || isSame) return true;
    }

    return false;
  }, [currentStatus?.value, selectedDate]);

  const [planDisabled, approveDisabled] = useMemo(() => {
    switch (currentStatus?.value) {
      case 1:
        return [false, true];
      case 2:
        return [true, false];
      case 3:
        return [true, true];
      case 5:
        return [false, true];
      case 6:
        return [true, false];
      case 7:
        return [true, approveDisabledByDate];
      case 8:
        return [true, approveDisabledByDate];
      case 9:
        return [true, true];
      default:
        return [true, true];
    }
  }, [approveDisabledByDate, currentStatus?.value]);

  const deductionButtonsDisabled = useMemo(() => {
    const iikoDeductionDisabled =
      !deductionsInfo || deductionsInfo.data.some(item => item.hold_by_writeoffs !== 0);
    const inventoryDeductionDisabled =
      !deductionsInfo || deductionsInfo.data.some(item => item.hold_by_inventory !== 0);
    return [iikoDeductionDisabled, inventoryDeductionDisabled];
  }, [deductionsInfo]);

  const buttons = useMemo(() => {
    if (isDeduction)
      return (
        <div className={cls["buttons-group"]} style={{ flexDirection: "row" }}>
          <button
            className={cls["button-primary"]}
            style={{ width: "fit-content" }}
            onClick={onIIKODeduction}
            disabled={deductionButtonsDisabled[0]}
          >
            Рассчитать удержания из iiko
          </button>
          <button
            className={cls["button-primary"]}
            style={{ width: "fit-content" }}
            disabled={deductionButtonsDisabled[1]}
            onClick={rewardByShortagesConfirm}
          >
            Рассчитать удержания по инвенте
          </button>
        </div>
      );
    return (
      <div className={cls["buttons-group"]} style={{ flexDirection: "row" }}>
        <button className={cls["button-primary"]} disabled={planDisabled} onClick={planClick}>
          Запланировать
        </button>
        <button className={cls["button-primary"]} disabled={approveDisabled} onClick={approveClick}>
          Утвердить
        </button>
      </div>
    );
  }, [
    approveClick,
    approveDisabled,
    deductionButtonsDisabled,
    isDeduction,
    onIIKODeduction,
    planClick,
    planDisabled,
    rewardByShortagesConfirm,
  ]);

  return (
    <div className={cls["fund__header"]}>
      <div className={cls["fund__header-container"]}>
        <div style={{ display: "flex", gap: "20px", justifySelf: "start" }}>
          <MonthSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          <div className={cls["tabs__container"]}>
            <button
              className={netOrGross === "gross" ? cls["tab__active"] : cls["tab"]}
              disabled={netOrGross === "gross"}
              onClick={() => setNetOrGross("gross")}
            >
              Начислено
            </button>
            <button
              className={netOrGross === "net" ? cls["tab__active"] : cls["tab"]}
              disabled={netOrGross === "net"}
              onClick={() => setNetOrGross("net")}
            >
              На руки
            </button>
          </div>
        </div>
        <Segmented
          className={cls["tabs"]}
          value={FundTabs[activeTab]}
          options={[
            "Выплаты и удержания",
            "Вознаграждения за смены",
            "Контракты",
            "Премии ТБУ",
            "Премии",
            "Управляющий",
          ]}
          onChange={(val: string) => setActiveTab((FundTabs as any)[val])}
        />
        <TableInfoMessage />
        {buttons}
      </div>
    </div>
  );
};

export const FundHeader = observer(FundHeaderComponent);
