import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import cls from "./table-styles.module.scss";
import { Dayjs } from "dayjs";
import { Employee } from "./employee";
import { IBepUser } from "../types";
import { useStore } from "store";
import { addSpaces } from "assets/utils";

interface EmployeesTableProps {
  days: Dayjs[];
  employees: IBepUser[];
  title: string;
}

const EmployeesTableComponent: FC<EmployeesTableProps> = ({ days, employees, title }) => {
  const {
    RootStore: {
      SalaryFundStore: { netOrGross },
    },
  } = useStore();

  const approve_sum = useMemo(
    () => employees.reduce((acc, cur) => acc + Number(cur.approved[`approved_${netOrGross}`]), 0),
    [employees, netOrGross]
  );

  const titleData = useMemo(() => {
    switch (title) {
      case "helpers":
        return "Хелперы";
      case "joe":
        return "Работники кухни";
      default:
        return "Работники бара";
    }
  }, [title]);

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={44} />
        <col width={185} />
        <col width={135} />
        {days.map(d => (
          <>
            <col width={113} />
            <col width={113} />
          </>
        ))}
        <col width={113} />
        <col width={113} />
        <col width={113} />
      </colgroup>
      <thead className={cls["thead"]} style={{ top: "33px" }}>
        <tr className={cls["tr"]}>
          <th className={`${cls["th"]} ${cls["sticky"]}`}>№</th>
          <th
            className={`${cls["th"]} ${cls["sticky"]}`}
            style={{ textAlign: "left", left: "44px" }}
          >
            {titleData}
          </th>
          <th
            className={`${cls["th"]} ${cls["sticky"]}`}
            style={{ textAlign: "left", left: "calc(185px + 44px)" }}
          >
            Премия
          </th>
          {days.map(d => (
            <>
              <th className={cls["th"]}>План</th>
              <th className={cls["th"]}>Факт</th>
            </>
          ))}
          <th className={cls["th"]}>План</th>
          <th className={cls["th"]}>Факт</th>
          <th className={cls["th"]}></th>
        </tr>
      </thead>
      <tbody className={cls["tbody"]}>
        {employees.map((employee, i) => (
          <Employee key={employee.id} days={days} index={i + 1} employee={employee} />
        ))}
      </tbody>
      <tfoot className={cls["tfoot"]}>
        <tr>
          <td colSpan={2} className={cls["td"]}>
            Всего, ₽
          </td>
          <td
            className={`${cls["td"]} ${cls["sticky"]}`}
            style={{ left: "calc(185px + 44px)" }}
          ></td>
          {days.map(d => (
            <>
              <td className={cls["td"]}></td>
              <td className={cls["td"]}></td>
            </>
          ))}
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}>{addSpaces(approve_sum.toFixed(2))}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export const EmployeesTable = observer(EmployeesTableComponent);
