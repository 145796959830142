import { observer } from "mobx-react-lite";
import { FC, useContext, useMemo } from "react";
import cls from "../styles.module.scss";
import { useNavigate } from "react-router";
import { BackButton, BucketIcon } from "../icons";
import { useStore } from "store";
import { TransactionContext } from "..";

interface TransactionHeaderProps {
  onSave: () => void;
  onApprove: () => void;
}

const TransactionHeaderComponent: FC<TransactionHeaderProps> = ({ onSave, onApprove }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { transaction, deleteTransactionConfirm },
    },
  } = useStore();

  const { isCash } = useContext(TransactionContext);

  const navigate = useNavigate();

  const onDeleteHandler = () => {
    deleteTransactionConfirm(() => {
      navigate(-1);
    });
  };

  const title = useMemo(() => (isCash ? "Кассовый ордер" : "Платежное поручение"), [isCash]);

  return (
    <div className={cls["header"]}>
      <div className={cls["breadcrumbs"]}>
        <p className={cls["grey"]} onClick={() => navigate("/main/statements/")}>
          Выписка из банка /
        </p>
        <p className={cls["black"]}>{title}</p>
      </div>
      <div className={cls["title-container"]}>
        <div className={cls["text"]}>
          <div className={cls["backlink"]} onClick={() => navigate("/main/statements/")}>
            <BackButton />
          </div>
          <h2 className={cls["title"]}>{title}</h2>
        </div>
        {!(["Удалено", "В процессе утверждения", "Утверждено"] as any[]).includes(
          transaction?.status_title
        ) && (
          <div className={cls["buttons-group"]}>
            <button className={cls["transaction-delete__button"]} onClick={onDeleteHandler}>
              <BucketIcon color={"#EB5757"} /> Удалить
            </button>
            <button className="button-secondary" onClick={onApprove}>
              Утвердить
            </button>
            <button className="button-primary" onClick={onSave}>
              Сохранить
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const TransactionHeader = observer(TransactionHeaderComponent);
