import { useEffect,useMemo,useState } from 'react';
import "@carbon/styles/css/styles.css";
import "@carbon/charts-react/styles.css";
import "./styles.scss";
import styles from './styles.module.scss';
import { observer } from "mobx-react-lite";
import Skeleton from "react-loading-skeleton";
import { useStore } from "store";
import NoData from "../../ui/no-data/noData";
import { ConfigProvider,DatePicker } from 'antd';
import dayjs,{ Dayjs } from 'dayjs';
import locale from 'antd/locale/ru_RU';
import { Board } from './components/board';
import { addSpaces } from 'assets/utils';
import { DataTable, DataTableHeader } from 'ui-new/table';

// Модель выручки
export interface RevenueTableItem {
  "id": number;
  "date": string;
  "planning_revenue": number;
  "fact_revenue": number;
  "percent": number;
  "nonfiscRevenue": number;
  "dayOfWeek": string;
}

// Модель чеков
export interface ReceiptsTableItem {
  "id": number;
  "date": string;
  "count_plan": number;
  "count_fact": number;
  "average_plan": number;
  "average_fact": number;
  "dayOfWeek": string;
}

//Хедеры выручки
const headersRevenueTable: DataTableHeader<RevenueTableItem>[] = [
  {
    title: "Дата",
    value: "date"
  },
  {
    title: "День недели", // Добавляем новый столбец
    value: "dayOfWeek"
  },
  {
    title: "Плановая выручка, руб.",
    value: "planning_revenue"
  },
  {
    title: "Фактическая выручка, руб.",
    value: "fact_revenue"
  },
  {
    title: "Отклонение, %",
    value: "percent"
  },
  {
    title: "Нефискальная выручка, руб",
    value: "nonfiscRevenue"
  }
]

// Хедеры чеков
const headersReceiptsTable: DataTableHeader<ReceiptsTableItem>[] = [
  {
    title: "Дата",
    value: "date"
  },
  {
    title: "День недели", // Добавляем новый столбец
    value: "dayOfWeek"
  },
  {
    title: "Плановое кол-во чеков, руб.",
    value: "count_plan"
  },
  {
    title: "Фактическое кол-во чеков, руб.",
    value: "count_fact"
  },
  {
    title: "Средний чек, руб.",
    value: "average_plan"
  },
  {
    title: "Фактический средний чек, руб.",
    value: "average_fact"
  }
]

function getDayOfWeek(dateString: string): string {
  const daysOfWeek = [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота"
  ];
  const date = new Date(dateString);
  return daysOfWeek[date.getDay()];
}

const DashboardComponent = () => {
  const {
    RootStore: {
      DashboardStore: {
        isLoading,
        fetchGetBills,
        fetchGetReveneu,
        billAverage,
        billsCount,
        data,
        revenue_data,
        receipts_data,
        revenue_total,
        receipts_total
      },
    },
  } = useStore();
  
  // вспомогательные функциия для Выручки
  const revenue_items = revenue_data
  
  const enrichedRevenueData = useMemo(() => 
    revenue_items.map(item => ({
      ...item,
      dayOfWeek: getDayOfWeek(item.date)
    })), [revenue_items]);
  
  const revenueWithIds = useMemo(() => 
    enrichedRevenueData.map((item, index) => ({
      id: index + 1,
      ...item,
    })), [enrichedRevenueData]);
  
  const revenueWithFormattedDate = useMemo(() => 
    revenueWithIds.map((row) => {
      const originalDate = new Date(row.date);
      const formattedDate = `${originalDate.getFullYear()}-${String(originalDate.getMonth() + 1).padStart(2, "0")}-${String(originalDate.getDate()).padStart(2, "0")}`;
      return { ...row, date: formattedDate };
    }), [revenueWithIds]);

    // вспомогательные функции для Чеков
    // вспомогательные функциия для Выручки
  const receipts_items = receipts_data
  
  const enrichedReceiptsData = useMemo(() => 
    receipts_items.map(item => ({
      ...item,
      dayOfWeek: getDayOfWeek(item.date)
    })), [receipts_items]);
  
  const receiptsWithIds = useMemo(() => 
    enrichedReceiptsData.map((item, index) => ({
      id: index + 1,
      ...item,
    })), [enrichedReceiptsData]);
  
  const receiptsWithFormattedDate = useMemo(() => 
    receiptsWithIds.map((row) => {
      const originalDate = new Date(row.date);
      const formattedDate = `${originalDate.getFullYear()}-${String(originalDate.getMonth() + 1).padStart(2, "0")}-${String(originalDate.getDate()).padStart(2, "0")}`;
      return { ...row, date: formattedDate };
    }), [receiptsWithIds]);

// Тут описан компонент для футера таблицы Выручки
const revenueTableFooter = () => {
  return <tr className={styles.pageTableFooter}>
      <td colSpan={2} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>Итого за месяц:</span>
      </td>
      <td colSpan={1} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>{revenue_total.plan_sum}</span>
      </td>
      <td colSpan={1} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>{revenue_total.fact_sum}</span>
      </td>
      <td colSpan={1} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>{revenue_total.percent}</span>
      </td>
      <td colSpan={3} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>{revenue_total.nonfisc_sum}</span>
      </td>
  </tr>
}

// Тут описан компонент для футера таблицы Выручки
const receiptsTableFooter = () => {

  return <tr className={styles.pageTableFooter}>
      <td colSpan={2} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>Итого за месяц:</span>
      </td>
      <td colSpan={1} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>{receipts_total.count_plan}</span>
      </td>
      <td colSpan={1} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>{receipts_total.count_fact}</span>
      </td>
      <td colSpan={1} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>{receipts_total.average_plan}</span>
      </td>
      <td colSpan={3} className={styles.pageTableFooterColumn}>
          <span className={styles.textCell}>{receipts_total.average_fact}</span>
      </td>
  </tr>
}

  const [day,setDay] = useState<Dayjs | null>(null);

  const getCurrentMonth = (): string => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}`;
  };
  const [selectedMonth, setSelectedMonth] = useState<string>(getCurrentMonth());

  useEffect(() => {
    fetchGetBills(day?.format('YYYY-MM-DD'));
  },[day,fetchGetBills]);

// Эффект, который вызывается при изменении `day`
  useEffect(() => {
    if (day) {
      const newMonth = `${day.year()}-${String(day.month() + 1).padStart(2, "0")}`; // Dayjs: month() возвращает 0-11
      setSelectedMonth(newMonth); // Обновляем `selectedMonth`
    }
  }, [day]);

  useEffect(() => {
    fetchGetReveneu(selectedMonth);
  },[selectedMonth, fetchGetReveneu]);

  const board = useMemo(() => {
    if (isLoading) return <Skeleton width={"100%"} height={"500px"} />;

    if (data.length === 0) return <NoData />;

    return <Board />;
  },[isLoading,data.length]);

  return (
    <div className="dashboard">
      <h2 className="dashboard__title">Анализ продаж</h2>
      <div className="dashboard__options">
        <ConfigProvider locale={locale}>
          <DatePicker
            value={day}
            onChange={setDay}
            placeholder='Выбрать день'
            minDate={dayjs().add(-1,'month')}
            maxDate={dayjs().add(-1,'day')}
            format={{ format: 'DD.MM.YYYY' }}
          />
        </ConfigProvider>
        <div className='dashboard__info--container'>
          <div className='dashboard__info'>
            <h3>Количество<br /> чеков {day && <>на <br />{day.format('DD.MM.YYYY')}</>}</h3>
            <p>{addSpaces(billsCount)} шт.</p>
          </div>
          <div className='dashboard__info'>
            <h3>Средний<br /> чек {day && <>на <br />{day.format('DD.MM.YYYY')}</>}</h3>
            <p>{addSpaces(billAverage)} руб.</p>
          </div>
        </div>
      </div>
      {board}
      <DataTable headers={headersRevenueTable} items={revenueWithFormattedDate} itemKey='id' tableFooter={revenueTableFooter}></DataTable>
      <DataTable headers={headersReceiptsTable} items={receiptsWithFormattedDate} itemKey='id' tableFooter={receiptsTableFooter}></DataTable>
    </div>
  );
};

export const DashboardPage = observer(DashboardComponent);