import { SelectProps, TreeSelect, TreeSelectProps } from "antd";
import cls from "./styles.module.scss";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { ReactNode } from "react";

interface SelectFieldProps<
  T extends FieldValues,
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
> extends UseControllerProps<T> {
  label?: ReactNode;

  options: SelectProps["options"];
  selectProps?: React.PropsWithChildren<TreeSelectProps<ValueType, OptionType>>;
  className?: string;
}

const ArrowDown = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7L8 10L12 7"
        stroke="black"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 7L8 10L12 7"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const SelectTreeFieldComponent = <T extends FieldValues>({
  label,
  className,
  options,
  selectProps,
  ...props
}: SelectFieldProps<T>) => {
  const { field, fieldState } = useController(props);

  return (
    <div className={`${cls["selectContainer"]} ${className}`}>
      {label && (
        <label className={cls["label"]} htmlFor={props.name}>
          {label}
        </label>
      )}
      <TreeSelect
        {...field}
        id={props.name}
        suffixIcon={<ArrowDown />}
        treeData={options}
        className={`${fieldState.error ? cls["select-error"] : ""} `}
        {...selectProps}
      />
      <p className={cls["errorText"]}>{fieldState.error?.message}</p>
    </div>
  );
};

export const SelectTreeField = SelectTreeFieldComponent;
