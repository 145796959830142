import { FC, useContext, useMemo } from "react";
import cls from "../../styles.module.scss";
import { EInputStyleVariant, Input } from "ui-new/input";
import { useFormContext } from "react-hook-form";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { TransactionContext } from "../..";
interface DateAndNumberProps {
  allDisabled: boolean;
}

const DateAndNumberComponent: FC<DateAndNumberProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { transaction },
    },
  } = useStore();

  const { isCash } = useContext(TransactionContext);

  const { control } = useFormContext<{ date_delivery: Date; number: number }>();

  const [defaultDate, defaultNumber] = useMemo(
    () => [transaction?.date_delivery, transaction?.number],
    [transaction]
  );

  return (
    <div className={cls["row"]}>
      <Input
        label="Дата проведения"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="date"
        name="date_delivery"
        disabled={allDisabled}
        inputProps={{
          max: dayjs().format("YYYY-MM-DD"),
          min: "1970-01-01",
          disabled: !!defaultDate && defaultDate.length > 0,
        }}
      />
      <Input
        label="№ документа"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="string"
        name="number"
        placeholder="Введи № документа"
        disabled={allDisabled}
        inputProps={{
          disabled: isCash ? false : !!defaultNumber && defaultNumber.length > 0,
        }}
      />
    </div>
  );
};

export const DateAndNumber = observer(DateAndNumberComponent);
