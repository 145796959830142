import React from "react";
import styles from "./styles.module.scss";
import { Skeleton } from "../../skeleton";

export interface Props {
  cellCount: number;
  rowCount: number;
}

export const TableSkeleton = (props: Props) => {
  const { cellCount, rowCount } = props;

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.headerRow}>
          {Array.from({ length: cellCount }).map(() => (
            <th className={`${styles.headerCell} ${styles.th}`}>
              <div className={styles.textCell}>
                <Skeleton width="107px" height="16px" />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: rowCount }).map(() => (
          <tr>
            {Array.from({ length: cellCount }).map(() => (
              <td className={`${styles.bodyCell} ${styles.td}`}>
                <div className={styles.textCell}>
                  <Skeleton width="107px" height="16px" />
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
