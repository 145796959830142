import { observer } from "mobx-react-lite";
import { EmployeesTable } from "./employees-table";
import { useEffect, useMemo } from "react";
import cls from "../styles.module.scss";
import { addSpaces } from "assets/utils";
import { useStore } from "store";

const BountyComponent = () => {
  const {
    RootStore: {
      SalaryFundStore: {
        tableShiftItems: { helpers, barista, joe },
        netOrGross,
        selectedDate,
        fetchGetShifts,
        currentShiftsStatus,
      },
    },
  } = useStore();

  const footerIsVisible =
    [helpers.length, barista.length, joe.length].filter(l => l > 0).length > 1;
  const sum = useMemo(() => {
    if (!footerIsVisible) return [0, 0, 0, 0, 0, 0];

    let firstPlanSum = 0;
    let firstFactSum = 0;
    let secondPlanSum = 0;
    let secondFactSum = 0;
    let approveSum = 0;
    barista.forEach(val => {
      firstPlanSum += val[`first_period_plan_${netOrGross}`] ?? 0;
      firstFactSum += val[`first_period_fact_${netOrGross}`] ?? 0;
      secondPlanSum += val[`second_period_plan_${netOrGross}`] ?? 0;
      secondFactSum += val[`second_period_fact_${netOrGross}`] ?? 0;
      approveSum += val[`total_approved_${netOrGross}`] ?? 0;
    });
    joe.forEach(val => {
      firstPlanSum += val[`first_period_plan_${netOrGross}`] ?? 0;
      firstFactSum += val[`first_period_fact_${netOrGross}`] ?? 0;
      secondPlanSum += val[`second_period_plan_${netOrGross}`] ?? 0;
      secondFactSum += val[`second_period_fact_${netOrGross}`] ?? 0;
      approveSum += val[`total_approved_${netOrGross}`] ?? 0;
    });
    helpers.forEach(val => {
      firstPlanSum += val[`first_period_plan_${netOrGross}`] ?? 0;
      firstFactSum += val[`first_period_fact_${netOrGross}`] ?? 0;
      secondPlanSum += val[`second_period_plan_${netOrGross}`] ?? 0;
      secondFactSum += val[`second_period_fact_${netOrGross}`] ?? 0;
      approveSum += val[`total_approved_${netOrGross}`] ?? 0;
    });

    firstPlanSum = Math.floor(firstPlanSum * 100) / 100;
    firstFactSum = Math.floor(firstFactSum * 100) / 100;
    secondPlanSum = Math.floor(secondPlanSum * 100) / 100;
    secondFactSum = Math.floor(secondFactSum * 100) / 100;
    approveSum = Math.floor(approveSum * 100) / 100;
    return [firstPlanSum, firstFactSum, secondPlanSum, secondFactSum, approveSum];
  }, [footerIsVisible, barista, joe, helpers, netOrGross]);
  const sumData = useMemo(
    () =>
      sum.map((s, i) => {
        let isGray = false;
        if (i === 0 && currentShiftsStatus?.value === 1) isGray = true;

        if (i === 1 && (currentShiftsStatus?.value ?? 0) < 5) isGray = true;

        if (i === 2 && (currentShiftsStatus?.value ?? 0) < 6) isGray = true;

        if (i === 3 && (currentShiftsStatus?.value ?? 0) < 9) isGray = true;

        if (i === 4 && !([6, 7, 9, 10] as any[]).includes(currentShiftsStatus?.value))
          isGray = true;

        if (i === 5 && !([7, 9, 10] as any[]).includes(currentShiftsStatus?.value)) isGray = true;

        return (
          <td className={cls["td"]} style={isGray ? { color: "#999CA6" } : {}}>
            {addSpaces(s)}
          </td>
        );
      }),
    [sum, currentShiftsStatus?.value]
  );

  useEffect(() => {
    fetchGetShifts();
  }, [fetchGetShifts, selectedDate]);

  return (
    <div>
      {barista.length > 0 && (
        <EmployeesTable
          title="Работники бара"
          className={cls["table-rounding__top"]}
          shiftList={barista}
        />
      )}
      {helpers.length > 0 && (
        <EmployeesTable
          title="Хелперы"
          className={barista.length === 0 ? cls["table-rounding__top"] : ""}
          shiftList={helpers}
        />
      )}
      {joe.length > 0 && (
        <EmployeesTable
          title="Работники кухни"
          className={barista.length === 0 && helpers.length === 0 ? cls["table-rounding__top"] : ""}
          shiftList={joe}
        />
      )}
      {footerIsVisible && (
        <table
          style={{ position: "sticky", bottom: "0" }}
          className={`${cls["table"]} ${cls["table-rounding__bottom"]}`}
        >
          <colgroup>
            <col width={44} />
            <col width={207} />
            <col width={181} />
            <col width={115} />
            <col width={115} />
            <col width={115} />
            <col width={115} />
            <col width={115} />
          </colgroup>
          <tbody className={`${cls["tfoot"]} ${cls["total-tfoot"]} ${cls["rounding"]}`}>
            <tr className={cls["tr"]}>
              <td className={cls["td"]} colSpan={3}>
                Всего
              </td>
              {sumData}
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export const Bounty = observer(BountyComponent);
