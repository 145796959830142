import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";
import { Modal } from "widgets/modal";
import { ModalButtons } from "widgets/modal/buttons";

interface DeleteModalProps {
  onClose: () => void;
  onSave: (comment: string) => void;
}

const DeleteModalComponent: FC<DeleteModalProps> = ({ onClose, onSave }) => {
  const { control, handleSubmit } = useForm();

  return (
    <Modal title="Пометить на удаление" maxWidth="550px" onClose={onClose}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Input
          control={control}
          label="Причина удаления"
          variant={EInputStyleVariant.basicInput}
          name="comment"
          type="text"
        />
        <div style={{ alignSelf: "flex-end" }}>
          <ModalButtons
            primaryButtonLabel="Удалить"
            onPrimaryButtonClick={handleSubmit(({ comment }) => onSave(comment))}
            secondaryButtonLabel="Отмена"
            onSecondaryButtonClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export const DeleteModal = observer(DeleteModalComponent);
