import { FC } from "react";
import { EInputStyleVariant, Input } from "ui-new/input";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react-lite";
interface DateAndNumberProps {
  allDisabled: boolean;
}

const ResponsibleComponent: FC<DateAndNumberProps> = ({ allDisabled }) => {
  const { control } = useFormContext<{ responsible: string }>();

  return (
    <Input
      label="Ответственный"
      control={control}
      variant={EInputStyleVariant.basicInput}
      type="string"
      name="responsible"
      placeholder="Ответственный"
      disabled={allDisabled}
      inputProps={{
        disabled: true,
      }}
    />
  );
};

export const ResponsibleField = observer(ResponsibleComponent);
