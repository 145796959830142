import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "store";
import cls from "./styles.module.scss";
import { Table } from "./table/table";
import { showNotificationModal } from "ui-new/alert";
import { AddContractModal } from "./add-contract-modal";

const ContractsPageComponent = () => {
  const {
    RootStore: {
      HeaderStore: { profileData },
      ContractsStore: { fetchGetDeals },
    },
  } = useStore();

  const [addModalIsOpen, setAddModalIsOpen] = useState(false);

  useEffect(() => {
    fetchGetDeals();
  }, [fetchGetDeals]);

  const canContractsView = useMemo(() => {
    return profileData?.job_title === "Управляющий";
  }, [profileData?.job_title]);

  useEffect(() => {
    if (canContractsView) return;

    showNotificationModal({
      title: "Ошибка прав доступа",
      type: "failure",
      errors: ["Не соответствие прав доступа, невозможно выполнить операцию"],
    });
  }, [canContractsView]);

  if (!canContractsView) {
    return null;
  }

  return (
    <div className={cls["wrapper"]}>
      <h3 className={cls["title"]}>Договоры с поставщиками</h3>
      <div className={cls["container"]}>
        <button className={cls["button-primary"]} onClick={() => setAddModalIsOpen(true)}>
          Добавить договор
        </button>
        <Table setAddModalIsOpen={setAddModalIsOpen} />
      </div>
      {addModalIsOpen && <AddContractModal onClose={() => setAddModalIsOpen(false)} />}
    </div>
  );
};

export const ContractsPage = observer(ContractsPageComponent);
