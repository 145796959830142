import {
  IMoneyTransaction,
  IMoneyTransactionCashFlowArticle,
  IMoneyTransactionCommitment,
  IMoneyTransactionOperationType,
  IMoneyTransactionPayType,
  IMoneyTransactionPnLArticle,
  IMoneyTransactionStatus,
  IMoneyTransactionTax,
  IMoneyTransactionType,
} from "types/money-transactions";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { onError } from "./schedulePlanning";
import { SortOrder } from "assets/types";
import { IUser } from "pages/salary-fund/types";
import { INeedleToPayUser, ISalary } from "pages/money-transactions/money-transaction-edit/types";
import { IContragent } from "types/directories";

export interface IStatementsListMeta {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface IGetBankStatementsListParams {
  page: number;
  per_page?: number;
  contragent?: number[];
  //YYYY-MM-DD
  date_from?: string;
  date_to?: string;

  //По number, amount, contragent_inn
  search?: string;

  type?: any[];
  pay_type?: any[];
  operation_type?: any[];
  status?: any[];

  show?: boolean;

  sort_by?: keyof IMoneyTransaction;
  sort_direction?: SortOrder;
}

export async function getMoneyTransactions(params: IGetBankStatementsListParams) {
  try {
    const response = await api.post<
      IBaseResponse<{ data: IMoneyTransaction[]; meta: IStatementsListMeta }>
    >("money_transactions/list", params);
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getMoneyTransaction(transactionId: IMoneyTransaction["id"]) {
  try {
    const response = await api.post<IBaseResponse<IMoneyTransaction>>("money_transactions/get", {
      id: transactionId,
    });
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function updateMoneyTransaction(body: Partial<IMoneyTransaction>) {
  try {
    const response = await api.post<IBaseResponse<IMoneyTransaction>>(
      "money_transactions/update",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function approveMoneyTransaction(transactionId: IMoneyTransaction["id"]) {
  try {
    const response = await api.post<IBaseResponse<IMoneyTransaction>>(
      "money_transactions/approve",
      { id: transactionId }
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function deleteMoneyTransaction(id: IMoneyTransaction["id"]) {
  try {
    const response = await api.post<IBaseResponse<IMoneyTransaction>>("money_transactions/delete", {
      id,
    });
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function addStatementsFile(formData: FormData) {
  try {
    const response = await api.post<
      IBaseResponse<{ count: number; error: number; success: number; skipped: number }>
    >(`money_transactions/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.data;
  } catch (e) {
    return onError(e);
  }
}

export async function getTransactionTypes() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionType[]>>(
      "directories/money_transactions/types"
    );
    console.log(response);

    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionPaymentTypes() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionPayType[]>>(
      "directories/money_transactions/pay_types"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionOperationTypes() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionOperationType[]>>(
      "directories/money_transactions/operation_types"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionStatuses() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionStatus[]>>(
      "directories/money_transactions/statuses"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionCashFlowArticles() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionCashFlowArticle[]>>(
      "directories/money_transactions/cash_flow_articles"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getPnLArticles() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionPnLArticle[]>>(
      "directories/money_transactions/pnl_articles"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getCommitments() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionCommitment[]>>(
      "directories/money_transactions/commitments"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getTaxes() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionTax[]>>(
      "directories/money_transactions/taxes"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getNeedleToPay() {
  try {
    const response = await api.get<IBaseResponse<INeedleToPayUser[]>>(
      "directories/money_transactions/needle_to_pay"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getEmployeesAndHelpers() {
  try {
    const response = await api.get<IBaseResponse<IUser[]>>(
      "directories/money_transactions/employees_and_helpers"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getContragentsInTransactions() {
  try {
    const response = await api.get<IBaseResponse<IContragent[]>>(
      "directories/money_transactions/contragents"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}
