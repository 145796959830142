import styles from './styles.module.scss';
import type {ChangeEvent} from "react";

export interface Props {
    onChange: (value: string) => void;
    value: string | number;
    labelInside: string;
    labelValue: string;
}

const schema = /^\d+(\.\d+)?$/;

export const PeriodInput = (props: Props) => {
    const {
        onChange,
        value,
        labelInside,
        labelValue
    } = props;

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        let value = target.value;

        if (!schema.test(value)) {
            value = value.slice(0, -1);
            e.target.value = value;
        }

        if (Number(value) > 100) {
            value = String(100);
            e.target.value = value;
        }

        onChange(value)
    }

    return <label className={styles.wrapper}>
        <span>{labelValue}</span>
        <div className={styles.inputWrapper}>
            <input maxLength={3} className={styles.input} type="text" value={value} onChange={handleInput} />
            <span className={styles.labelInside}>{labelInside}</span>
        </div>
    </label>
}