import React, { FC, useEffect, useState } from "react";
import PnlReportRow from "./pnl-report-row";
import { PnlReportRowData, PnlReportRowDataTest } from "../types";
import axios from "axios";
import Cookies from "js-cookie";
import { Dayjs } from "dayjs";
import { getPnlData } from "api/pnl";

const tableData: PnlReportRowData[] = [
  {
    section: "Выручка по видам оплат, ₽",
    months: [
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  {
    section: "Средняя выручка и средний чек, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  {
    section: "Точка безубыточности, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: false,
    details: [],
  },
  {
    section: "Выручка по категориям, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  {
    section: "Прямые затраты, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  {
    section: "Маржинальный доход, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: false,
    details: [],
  },
  {
    section: "Косвенные затраты, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  {
    section: "EBITDA, ₽,",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: false,
    details: [],
  },
  {
    section: "Иные доходы и расходы от операционной деят., ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  {
    section: "Расчетная прибыль, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: false,
    details: [],
  },
  {
    section: "Вознаграждение управляющего, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  {
    section: "Доходы и расходы от инвестиционной деят. ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  {
    section: "Чистая прибыль, ₽,",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: false,
    details: [],
  },
  {
    section: "Свободный денежн. поток (FCF), ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: false,
    details: [],
  },
  {
    section: "Дивиденды, ₽",
    months: [
      { plan: 1234, fact: 1234 },
      { plan: 1234, fact: 1234 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
      { plan: 123, fact: 123 },
    ],
    isCategory: true,
    details: [
      {
        section: "Test 1",
        months: [
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
          { plan: 123, fact: 123 },
        ],
      },
    ],
  },
  // Добавьте остальные строки
];

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

interface PnlReportTableProps {
  date: Date;
}

const PnlReportTable: FC<PnlReportTableProps> = ({ date }) => {
  const [tableDataFetched, setTableDataFetched] = useState<
    PnlReportRowDataTest[]
  >([]);

  // Загрузить данные при монтировании компонента (или по кнопке)
  useEffect(() => {
    const period = date.getFullYear().toString();
    getPnlData(period).then((response) => {
      console.log(response);
      if (response) {
        setTableDataFetched(response);
      } else {
        setTableDataFetched([]);
      }
    });
  }, [date]);

  return (
    <table className="report-table">
      <colgroup>
        <col width={300} />
        {months.map(() => (
          <>
            <col width={120} />
            <col width={120} />
          </>
        ))}
      </colgroup>
      <thead>
        <tr>
          <th rowSpan={2} className="title">
            Разделы
          </th>
          {months.map((month) => (
            <th colSpan={2} className="monthCell">
              {month}
            </th>
          ))}
        </tr>
        <tr className="subHeader">
          {months.map(() => (
            <>
              <th className="planCell">План</th>
              <th className="factCell">Факт</th>
            </>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableDataFetched.map((row, index) => (
          <PnlReportRow
            key={index}
            data={row}
            isCategory={!row.is_indicator}
          />
        ))}
      </tbody>
    </table>
  );
};

export default PnlReportTable;
