import { FC,useCallback,useEffect,useState } from 'react';
import cls from './styles.module.scss';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';

let timer: number;

interface DateSelectorProps {
  withDay?: boolean;

  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  withMonth?: boolean;
  withYear?: boolean;
}

const MonthSelectorComponent: FC<DateSelectorProps> = ({ selectedDate,setSelectedDate,withDay = false,minDate,maxDate,withMonth=true, withYear=true }) => {
  const [currentDate,setCurrentDate] = useState(selectedDate);

  useEffect(() => {
    timer = window.setTimeout(() => setSelectedDate(currentDate),500);
    return () => window.clearTimeout(timer);
  },[currentDate,setSelectedDate]);

  const setDate = useCallback((step: number) => {
    setCurrentDate(prev => {
      const newDate = new Date(prev);
      newDate.setDate(prev.getDate() + step);
      return newDate;
    });
  },[]);

  const setMonth = useCallback((step: number) => {
    setCurrentDate(prev => {
      const newDate = new Date(prev);
      newDate.setMonth(prev.getMonth() + step);
      return newDate;
    });
  },[]);

  const setYear = useCallback((step: number) => {

    setCurrentDate(prev => {
      console.log(typeof prev);
      const newDate = new Date(prev);
      newDate.setFullYear(prev.getFullYear() + step);
      return newDate;
    });
  },[]);

  return (
    <div className={cls['date-selector']}>
      {withDay &&
        <div className={cls['month-selector']}>
          <button disabled={minDate && dayjs(minDate).isAfter(dayjs(currentDate).add(-1,'day'))} onClick={() => setDate(-1)} className={cls['button-prev']}></button>
          <p style={{ minWidth: '24px' }}>{dayjs(currentDate).locale(ru).format('DD')}</p>
          <button disabled={maxDate && dayjs(maxDate).isBefore(dayjs(currentDate).add(+1,'day'))} onClick={() => setDate(+1)} className={cls['button-next']}></button>
        </div>
      }
      {withMonth && 
        <div className={cls['month-selector']}>
          <button disabled={minDate && dayjs(minDate).isAfter(dayjs(currentDate).add(-1,'month'))} onClick={() => setMonth(-1)} className={cls['button-prev']}></button>
          <p style={{ minWidth: '90px' }}>{dayjs(currentDate).locale(ru).format('MMMM')}</p>
          <button disabled={maxDate && dayjs(maxDate).isBefore(dayjs(currentDate).add(+1,'month'))} onClick={() => setMonth(+1)} className={cls['button-next']}></button>
        </div>}
      {withYear && 
        <div className={cls['month-selector']}>
          <button disabled={minDate && dayjs(minDate).isAfter(dayjs(currentDate).add(-1,'year'))} onClick={() => setYear(-1)} className={cls['button-prev']}></button>
          <p style={{ minWidth: '43px' }}>{dayjs(currentDate).locale(ru).format('YYYY')}</p>
          <button disabled={maxDate && dayjs(maxDate).isBefore(dayjs(currentDate).add(+1,'year'))} onClick={() => setYear(+1)} className={cls['button-next']}></button>
        </div>}
    </div>
  );
};

export const MonthSelectorSeparated = MonthSelectorComponent;