import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo } from "react";
import cls from "./deduction.module.scss";
import { EmployeesTable } from "./employees-table";
import { useStore } from "store";
import { toJS } from "mobx";
import { addSpaces } from "assets/utils";

interface DeductionProps {}

const DeductionComponent: FC<DeductionProps> = () => {
  const {
    RootStore: {
      SalaryFundStore: {
        setInfoMessage,
        fetchGetDeductions,
        tableDeductionItems,
        selectedDate,
        holdAndPaidTotal,
      },
    },
  } = useStore();

  const {
    approved_net,
    hold_by_inventory,
    hold_by_ndfl,
    hold_by_writeoffs,
    needle,
    needle_last_period,
    needle_with_holds,
    paid,
  } = holdAndPaidTotal ?? {};

  useEffect(() => {
    fetchGetDeductions();
  }, [fetchGetDeductions, selectedDate]);

  useEffect(() => {
    setInfoMessage(null);
  }, []);

  const employeesData = useMemo(() => {
    return Object.entries(toJS(tableDeductionItems)).map(([key, items]) => {
      if (items.length === 0) return null;
      return <EmployeesTable key={key} title={key} employees={items} />;
    });
  }, [tableDeductionItems]);

  return (
    <div>
      <table className={cls["table"]}>
        <colgroup>
          <col width={44} />
          <col width={230} />
          <col width={184} />
          <col width={205} />
          <col width={122} />
          <col width={169} />
          <col width={140} />
          <col width={224} />
          <col width={108} />
          <col width={158} />
        </colgroup>
        <thead className={cls["thead"]}>
          <tr className={cls["tr"]}>
            <th className={`${cls["th"]} ${cls["sticky"]}`} colSpan={2}></th>
            <th className={`${cls["th"]} ${cls["sticky"]}`} colSpan={2}></th>
            <th className={`${cls["th"]} ${cls["sticky"]}`} colSpan={3}>
              Удержания
            </th>
            <th className={`${cls["th"]} ${cls["sticky"]}`} colSpan={3}></th>
          </tr>
        </thead>
        <tbody className={cls["tbody"]}>
          <tr>
            <td colSpan={10}>{employeesData}</td>
          </tr>
        </tbody>
        <tfoot className={cls["tfoot"]}>
          <tr className={cls["tr"]}>
            <td className={cls["td"]}></td>
            <td className={cls["td"]}>Всего, ₽</td>
            <td className={cls["td"]}>{addSpaces((needle_last_period ?? 0).toFixed(2))}</td>
            <td className={cls["td"]}>{addSpaces((approved_net ?? 0).toFixed(2))}</td>
            <td className={cls["td"]}>{addSpaces((hold_by_ndfl ?? 0).toFixed(2))}</td>
            <td className={cls["td"]}>{addSpaces((hold_by_inventory ?? 0).toFixed(2))}</td>
            <td className={cls["td"]}>{addSpaces((hold_by_writeoffs ?? 0).toFixed(2))}</td>
            <td className={cls["td"]}>{addSpaces((needle_with_holds ?? 0).toFixed(2))}</td>
            <td className={cls["td"]}>{addSpaces((paid ?? 0).toFixed(2))}</td>
            <td className={cls["td"]}>{addSpaces((needle ?? 0).toFixed(2))}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export const Deduction = observer(DeductionComponent);
