import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import cls from "./styles.module.scss";
import { BucketIcon } from "pages/money-transactions/money-transaction-edit/icons";
import { Control, Controller } from "react-hook-form";
import SelectField from "ui-new/select";
import { INeedleToPay } from "./employees-table";

interface EmployeeRowProps {
  employeesData: { label: string; value: number }[];
  employee: INeedleToPay;
  control: Control<any>;
  remove: (index: number) => void;
  index: number;
  lastIndex: number;
  allDisabled: boolean;
}

const EmployeeRowComponent: FC<EmployeeRowProps> = ({
  employeesData,
  control,
  remove,
  index,
  employee,
  allDisabled,
  lastIndex,
}) => {
  const contragentFieldsDisabled = useMemo(
    () =>
      (["Удалено", "В процессе утверждения", "Утверждено"] as any[]).includes(
        employee.status_title
      ),
    [employee]
  );

  return (
    <tr className={cls["tr"]}>
      <td className={cls["td"]}>
        <SelectField
          className={cls["employee-selector"]}
          control={control}
          name={`items.${index}.user_id`}
          options={employeesData}
          selectProps={{
            placeholder: "Выберите сотрудника",
            disabled: allDisabled || contragentFieldsDisabled,
          }}
        />
      </td>
      <td
        className={`${cls["td"]} ${allDisabled || contragentFieldsDisabled ? cls["disabled"] : ""}`}
      >
        <span
          className={cls["status"]}
          style={employee.status_title === "Ошибка" ? { color: "#EB5757" } : {}}
        >
          {employee.status_title}
        </span>
      </td>
      <td className={cls["td"]}>
        <Controller
          name={`items.${index}.amount`}
          control={control}
          render={({ field }) => (
            <input
              className={`${cls["input-sum"]} ${
                allDisabled || contragentFieldsDisabled ? cls["disabled"] : ""
              }`}
              {...field}
              type="number"
              placeholder="Введите сумму выплаты"
              disabled={allDisabled}
            />
          )}
        ></Controller>
      </td>
      <td
        className={`${cls["td"]} ${
          allDisabled || contragentFieldsDisabled || lastIndex === 0 ? cls["disabled"] : ""
        }`}
      >
        <button
          className={cls["bucket-button"]}
          disabled={allDisabled || lastIndex === 0}
          onClick={() => remove(index)}
        >
          <BucketIcon color="#EB5757" />
        </button>
      </td>
    </tr>
  );
};

export const EmployeeRow = observer(EmployeeRowComponent);
