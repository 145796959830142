import {type ChangeEvent, type ReactNode, useCallback, useEffect, useMemo, useRef, useState} from "react";
import styles from './styles.module.scss';
import type {IncomePlanningItem} from "../../../../types/income-planning";
import {useClickAway} from "react-use";

export interface Props {
    title: ReactNode;
    item: IncomePlanningItem;
    headerValue: keyof IncomePlanningItem;
    updateItem: (item: IncomePlanningItem, value: keyof IncomePlanningItem, newValue: number) => void;
    index: number;
    fillFullTable: () => void;
    setCurrentHeaderValue: (value: keyof IncomePlanningItem) => void;
}

const schemaInteger = /^\d+(\.\d+)?$/;
const schemaDecimal = /^(?:\d+(\.\d+)?|\d*\.)$/;

export const EditableCell = ({title, item, headerValue, updateItem, index, fillFullTable, setCurrentHeaderValue}: Props) => {

    const inputRef = useRef<null | HTMLInputElement>(null);
    const editableCellRef = useRef(null);
    const [isEditable, setEditable] = useState(false);
    const [value, setValue] = useState(String(item[headerValue]));

    const handleWrapperClick = () => {
        setEditable(true);

        if (!item[headerValue] || item[headerValue] === '0') {
            setValue('');
        } else {
            setValue(String(item[headerValue]));
        }
    }

    const showModal = () => {
        if (index !== 0) return;

        setCurrentHeaderValue(headerValue);
        if (value && value !== '0') return fillFullTable();
    };

    const handleInputClick = (event: any) => {
        event.stopPropagation();
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
            submit();
            return;
        }
    }

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const currentSchema = headerValue === 'average_receipt' ? schemaDecimal :  schemaInteger;
        let value = target.value;

        if (!currentSchema.test(value)) {
            value = value.slice(0, -1);
            e.target.value = value;
        }

        setValue(value)
    }

    const submit = () => {
        if (isEditable) {
            setEditable(false);
            updateItem(item, headerValue, Number(value));
            showModal();
        }
    }

    const renderCellNumberValue = useCallback((value: number | string | ReactNode) => {
        if (headerValue === 'receipts_count') return new Intl.NumberFormat('ru-RU').format(Number(value));

        return new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(Number(value));
    }, [headerValue]);

    const maxLength = useMemo(() => {
        return headerValue === 'average_receipt' ? 9 : 6;
    }, [headerValue])

    useClickAway(editableCellRef, submit);

    useEffect(() => {
        if (isEditable && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditable]);

    return <div ref={editableCellRef} onClick={handleWrapperClick}>
        {isEditable &&
            <input
                ref={inputRef}
                type='text'
                value={value}
                onClick={handleInputClick}
                onKeyDown={handleKeyDown}
                onChange={handleInput}
                className={styles.editableCellInput}
                maxLength={maxLength}
            />}
        {!isEditable && <span>{renderCellNumberValue(title)}</span>}
    </div>
}